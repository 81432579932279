import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, FormProps, Input, InputNumber } from "antd";
import { VFC } from "react";

import { requiredRule } from "../../helpers";
import { errorMessage, successMessage } from "../../helpers/message";
import { defaultErrorMessageWithStatus } from "../../i18n";
import { ValueList, ValueListField, ValueListFieldValueType } from "../../queries/api/types";
import { useValueListItemCreate } from "../../queries/valueListItems";
import { useValueListDetails } from "../../queries/valueLists";
import ApiResult from "../ApiResult";

const renderValueForm = ({
    title,
    name: fieldName,
    minValue,
    maxValue,
    isRequired: required,
    valueType,
}: ValueListField) => {
    let field;

    switch (valueType) {
        case ValueListFieldValueType.number:
            field = <InputNumber min={minValue} max={maxValue} placeholder="0" />;
            break;

        case ValueListFieldValueType.string:
            field = <Input placeholder="Saisir une valeur" />;
            break;

        default:
            field = null;
    }

    return (
        <Form.Item
            name={["fields", fieldName]}
            label={title}
            rules={required ? [requiredRule] : undefined}
            required={required}
            key={fieldName}
        >
            {field}
        </Form.Item>
    );
};

interface ValueListAddCardProps {
    valueListSlug: ValueList["slug"];
}

const ValueListAddCard: VFC<ValueListAddCardProps> = ({ valueListSlug }) => {
    const [form] = Form.useForm();
    const { data: valueList, isLoading, isError, error } = useValueListDetails(valueListSlug);
    const { mutate: createValueListItem, isLoading: isCreating } = useValueListItemCreate();
    const onSubmit: FormProps["onFinish"] = (values) => {
        createValueListItem(
            { valueListSlug, title: "dummy", meta: {}, ...values },
            {
                onSuccess: () => {
                    successMessage({ content: "Valeur ajoutée avec succès" });
                    form.resetFields();
                },
                onError: (error) => {
                    errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                },
            }
        );
    };

    return (
        <Card title={valueList?.title} loading={isLoading}>
            {isError ? (
                <ApiResult status={error?.response?.status} />
            ) : (
                <Form form={form} onFinish={onSubmit} layout="vertical" requiredMark>
                    {valueList?.fields?.map((field) => renderValueForm(field))}
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        htmlType="submit"
                        loading={isCreating}
                        block
                    >
                        Ajouter
                    </Button>
                </Form>
            )}
        </Card>
    );
};

export default ValueListAddCard;
