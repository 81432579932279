import { AxiosError } from "axios";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "../queries/api/apiClient";
import { ApiInfos } from "../queries/api/types";

export type Env = "development" | "preprod" | "production" | undefined;

function useEnv() {
    const [env, setEnv] = useState<Env>(import.meta.env.VITE_ENV as "development" | undefined);
    useQuery<ApiInfos, AxiosError>(
        ["apiInfos"],
        async () => await api.get<ApiInfos>("/").then((response) => response?.data),
        {
            onSuccess: (data) => {
                if (!env) {
                    setEnv(data.env);
                }
            },
        }
    );

    return env;
}

export default useEnv;
