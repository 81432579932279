import { antdDrawer, create, useModal } from "@ebay/nice-modal-react";
import { Button, Drawer, DrawerProps, Form, Spin } from "antd";
import { FormProps } from "antd/lib/form";

import OrganizationsFormItem from "../../components/forms/OrganizationsFormItem";
import RoleFormItem from "../../components/forms/RoleFormItem";
import UserAccountFields from "../../components/forms/UserAccountFields";
import { errorMessage, successMessage } from "../../helpers/message";
import { User } from "../../queries/api/types";
import { useUserCreate, useUserDetails, useUserUpdate } from "../../queries/users";

interface UserFormDrawerProps extends DrawerProps, Record<string, any> {
    userId?: User["id"];
}

const UserFormDrawer = create<UserFormDrawerProps>(({ userId, ...props }) => {
    const userFormModal = useModal();
    const isEditing = userId !== undefined;
    const [form] = Form.useForm();
    const { data: userData, isLoading } = useUserDetails(userId, {
        enabled: isEditing,
        onSuccess: (userData) => {
            form.setFieldsValue({
                user: {
                    ...userData,
                    role: userData?.role?.id,
                },
            });
        },
    });

    const { mutate: createUser, isLoading: isCreating } = useUserCreate();
    const { mutate: updateUser, isLoading: isUpdating } = useUserUpdate();

    const onSubmit: FormProps["onFinish"] = (values) => {
        if (!isEditing) {
            createUser(values.user, {
                onError: () => {
                    errorMessage({ content: "Une erreur est survenue lors de la création de l'utilisateur" });
                },
                onSuccess: () => {
                    successMessage({ content: "L'utilisateur a été créé avec succès" });
                    form.resetFields();
                    userFormModal.hide();
                },
            });
        } else {
            updateUser(
                { id: userId, ...values.user },
                {
                    onError: () => {
                        errorMessage({ content: "Une erreur est survenue pendant la mise à jour de l'utilisateur" });
                    },
                    onSuccess: () => {
                        successMessage({ content: "L'utilisateur a été mise à jour avec succès" });
                        userFormModal.hide();
                    },
                }
            );
        }
    };

    return (
        <Drawer
            title={isEditing ? "Modifier l'utilisateur" : "Créer un utilisateur"}
            width={420}
            {...props}
            {...antdDrawer(userFormModal)}
            placement={"right"}
        >
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    initialValues={
                        isEditing
                            ? {
                                  user: {
                                      ...userData,
                                      role: userData?.role?.id,
                                  },
                              }
                            : undefined
                    }
                >
                    <UserAccountFields isMe />
                    {isEditing ? null : <OrganizationsFormItem name={["user", "organization"]} />}

                    <RoleFormItem name={["user", "role"]} />

                    <Form.Item>
                        <Button type="primary" size="large" htmlType="submit" loading={isCreating || isUpdating} block>
                            {isEditing ? "Modifier l'utilisateur" : "Créer l'utilisateur"}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    );
});
export default UserFormDrawer;
