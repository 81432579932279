import { FC, useEffect, useState } from "react";
import { FormProps } from "antd/lib/form/Form";
import { Form, Button, Alert, Typography, Spin, message } from "antd";
import { Redirect } from "react-router-dom";

import { ResetPasswordPayload } from "../../queries/api/auth";
import logo from "../../assets/images/logo.svg";
import Seo from "../../components/Seo";
import PasswordInput from "../../components/PasswordInput";
import LoginLayout from "../../components/LoginLayout";
import ButtonLink from "../../components/ButtonLink";
import { checkIfTokenExpired } from "../../helpers";
import { getRoute, RoutePathName } from "../../routes";
import useQueryParams from "../../hooks/queryParams";
import validatePasswordRules from "../../helpers/passwords";
import { useAuth } from "../../context/AuthContext";

const ResetPassword: FC = () => {
    const { resetPassword, resendInvitation } = useAuth();
    const {
        mutate: sendResetPassword,
        error: resetPasswordError,
        isLoading: isResettingPassword,
        isSuccess: isResetPasswordSuccess,
    } = resetPassword;
    const {
        mutate: sendresendInvitation,
        error: resendInvitationError,
        isLoading: isResendingInvitation,
        isSuccess: isResendInvitationSuccess,
    } = resendInvitation;
    const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null);
    const [queryParams] = useQueryParams("reset-password");
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const [validate, setValidator] = useState<string | undefined>("onSubmit");
    const token = queryParams.get("token");
    const onFormValidSubmit: FormProps["onFinish"] = (values: ResetPasswordPayload) => {
        if (token) {
            sendResetPassword({
                ...values,
                token,
            });
        }
    };
    const onClickSendNewInvitation = () => {
        if (token) {
            sendresendInvitation({ token });
        }
    };
    const onSubmit = () => {
        setValidator(undefined);
    };

    let errorMessage =
        resetPasswordError ?? resendInvitationError
            ? "Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard."
            : null;

    if (resetPasswordError) {
        if (resetPasswordError?.response?.status === 404) {
            errorMessage = "Adresse e-mail non trouvée.";
        } else if (resetPasswordError?.response?.status === 400) {
            errorMessage = "Mot de passe non valide";
        } else {
            errorMessage = "Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard.";
        }
    }

    const resetPasswordForm = isResetPasswordSuccess ? (
        <>
            <Typography.Paragraph>Votre mot de passe a été modifié avec succès</Typography.Paragraph>
            <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                Connexion
            </ButtonLink>
        </>
    ) : (
        <>
            <Typography.Paragraph>
                <p>Merci de saisir un nouveau mot de passe ci-dessous</p>
            </Typography.Paragraph>
            <Form.Item
                label="Nouveau mot de passe"
                rules={[
                    { required: true, message: "Veuillez renseigner votre nouveau mot de passe" },
                    {
                        validator: async (_, value) => {
                            if (!value || !validatePasswordRules(value)) {
                                return await Promise.resolve();
                            }
                            return await Promise.reject(new Error("Mot de passe non valide"));
                        },
                    },
                ]}
                name="password"
                validateTrigger={validate}
            >
                <PasswordInput />
            </Form.Item>
            {errorMessage && <Alert type="error" message={errorMessage} className="mb-24" showIcon />}
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isResettingPassword}
                        onClick={onSubmit}
                        size="large"
                        disabled={
                            !form.isFieldsTouched(true) ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                        block
                    >
                        Réinitialiser votre mot de passe
                    </Button>
                )}
            </Form.Item>
        </>
    );

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (token) {
            setIsTokenExpired(token === null ? true : checkIfTokenExpired(token));
        }
    }, [setIsTokenExpired, token]);

    if (!token) {
        message.error("Lien non valide");
        return <Redirect to={getRoute(RoutePathName.login)} />;
    }

    return (
        <LoginLayout>
            <Seo title="Réinitialisation du mot de passe" />
            <Form
                form={form}
                className="login-form"
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
            >
                <Spin spinning={isTokenExpired === null}>
                    <img className="logo" src={logo} alt="logo" />
                </Spin>

                {isTokenExpired ? (
                    <>
                        {isResendInvitationSuccess ? (
                            <Typography.Paragraph>
                                <p>Vous allez recevoir une nouvelle invitation par email</p>
                            </Typography.Paragraph>
                        ) : (
                            <>
                                <Alert
                                    type="error"
                                    message={errorMessage ?? "L'invitation a expiré."}
                                    className="mb-24"
                                />
                                <Button
                                    onClick={onClickSendNewInvitation}
                                    loading={isResendingInvitation}
                                    type="primary"
                                    size="large"
                                >
                                    Renvoyer une invitation
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    resetPasswordForm
                )}
            </Form>
        </LoginLayout>
    );
};

export default ResetPassword;
