import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import jwt_decode from "jwt-decode";

import { User } from "../queries/api/types";

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = !import.meta.env.PROD;
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: dodgerblue; font-weight: bold", messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: cornflowerblue; font-weight: bold", messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: darkorange; font-weight: bold", messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: tomato; font-weight: bold", messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (user?: Pick<User, "firstName" | "lastName">) => {
    if (!user?.firstName && !user?.lastName) {
        return "";
    }

    return `${user?.firstName ?? ""}${user?.lastName ? ` ${user?.lastName}` : ""}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== "string") {
        return "";
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== "string") {
        return "";
    }

    return str.split(" ").map(capitalize).join(" ");
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(" ");
}

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
    } catch (error) {
        return true;
    }
};

export const urlSearchParamsToObject = (urlSearchParams: URLSearchParams) =>
    Array.from(urlSearchParams.entries()).reduce<Record<string, any>>((acc, param) => {
        if (Object.prototype.hasOwnProperty.call(acc, param[0])) {
            if (Array.isArray(acc[param[0]])) {
                return { ...acc, [param[0]]: [...acc[param[0]], param[1]] };
            } else {
                return { ...acc, [param[0]]: [acc[param[0]], param[1]] };
            }
        }

        return { ...acc, [param[0]]: param[1] };
    }, {});

export const requiredRule = { required: true, message: "champ requis" };

export const sortOrderConverter = (value: string) => {
    switch (value) {
        case "ascend":
            return "asc";
        case "descend":
            return "desc";
        default:
            return value;
    }
};

export const addDefaultColumnSorting = <T>(
    sort: string | undefined,
    sortOrder: string | undefined,
    columns: ColumnsType<T>
) => {
    return columns.map((column) => {
        if (sort && sortOrder && column.key === sort) {
            return {
                ...column,
                defaultSortOrder: sortOrder === "asc" ? ("ascend" as SortOrder) : ("descend" as SortOrder),
            };
        }
        return column;
    });
};

/**
 * Remove certain props from an object
 * @param obj Object
 * @param keys Object keys
 * @returns Object without certain props
 */
export function omit<T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
    const data = { ...obj };
    for (const key of keys) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete data[key];
    }
    return data;
}
