import { FC, ReactNode } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Form, Input, InputNumber, InputProps, Tabs } from "antd";
import { Rule } from "antd/lib/form";

import { classNames, requiredRule } from "../helpers";
import { LanguagesList, ValueListFieldValueType } from "../queries/api/types";
import Flag from "./Flag";

const { TabPane } = Tabs;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    form: any;
    editing: boolean;
    name: string;
    valueType: ValueListFieldValueType;
    inputProps?: InputProps;
    rules?: Rule[];
    required: boolean;
    translation: boolean;
    children: ReactNode;
}

const EditableCell: FC<EditableCellProps> = ({
    form,
    editing,
    name,
    valueType,
    required,
    translation,
    children,
    className,
    inputProps,
    rules,
    ...restProps
}) => {
    let inputNode: ReactNode;

    switch (valueType) {
        case "number":
            inputNode = (
                <InputNumber
                    onPressEnter={() => {
                        form.submit();
                    }}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                />
            );
            break;
        default:
            inputNode = (
                <Input
                    {...inputProps}
                    onPressEnter={() => {
                        form.submit();
                    }}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                />
            );
    }

    // ---------------------------------------
    // Tabs errors

    const tabHasError = (fields: any[]) => {
        return !!form
            .getFieldsError()
            .find((error: any) => error.name.join() === fields.join() && error.errors.length > 0);
    };

    return (
        <td className={classNames(className, editing && "editing")} {...restProps}>
            {editing ? (
                <>
                    {translation ? (
                        <Tabs className="tab-lang" animated={false}>
                            {LanguagesList.map((language) => (
                                <TabPane
                                    tab={
                                        <>
                                            <Flag language={language} />
                                            {tabHasError([name, language]) && (
                                                <ExclamationCircleFilled className="text-danger" />
                                            )}
                                        </>
                                    }
                                    forceRender
                                    key={language}
                                >
                                    <Form.Item name={[name, language]} rules={[requiredRule]}>
                                        {inputNode}
                                    </Form.Item>
                                </TabPane>
                            ))}
                        </Tabs>
                    ) : (
                        <Form.Item name={name} rules={[...(rules ?? []), ...(required ? [requiredRule] : [])]} noStyle>
                            {inputNode}
                        </Form.Item>
                    )}
                </>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
