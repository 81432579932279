import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { ListResponse, Permission, Role } from "./types";

export interface RoleCreatePayload {
    name: Role["name"];
}

export interface RoleUpdatePayload extends Omit<Role, "id"> {
    id?: Role["id"];
}

export type RoleIdPayload = Role["id"];

export type RoleListPayload = SearchPaginationQuery;
export type RoleListReponse = ListResponse<Role>;

export type RoleListPermissionsResponse = Permission[];

// Routes
export const list = async (payload?: RoleListPayload) => {
    return await api.get<RoleListReponse>(`/roles`, { params: payload }).then((response) => response?.data);
};

export const listPermissions = async () => {
    return await api.get<RoleListPermissionsResponse>(`/roles/permissions`).then((response) => response?.data);
};

export const create = async (payload: RoleCreatePayload) => {
    return await api.post(`/roles`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: RoleUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<Role>(`/roles/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: RoleIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.get<Role>(`/roles/${id}`).then((response) => response?.data);
};

export const remove = async (id?: RoleIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/roles/${id}`).then((response) => response?.data);
};
