import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import { Button, Modal, ModalProps } from "antd";

interface ConfirmationModalProps extends Omit<ModalProps, "visible" | "onCancel" | "afterClose"> {
    text: string;
    onConfirmation?: () => void;
    isLoading?: boolean;
}
const ConfirmationModal = NiceModal.create<ConfirmationModalProps>(({ text, onConfirmation, isLoading, ...props }) => {
    const confirmModal = useModal();

    return (
        <Modal
            {...props}
            {...antdModal(confirmModal)}
            afterClose={confirmModal.remove}
            footer={[
                <Button key="cancelBtn" onClick={confirmModal.hide}>
                    Non
                </Button>,

                <Button key="yesBtn" onClick={onConfirmation} type="primary" loading={isLoading}>
                    Oui
                </Button>,
            ]}
        >
            {text}
        </Modal>
    );
});
export default ConfirmationModal;
