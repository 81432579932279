import { FC } from "react";
import { Skeleton } from "antd";

import "../assets/styles/CardSkeleton.less";

interface CardSkeletonProps {
    image?: boolean;
    rows?: number;
}

const CardSkeleton: FC<CardSkeletonProps> = ({ image, rows }) => (
    <div className="card-skeleton">
        {image && <header />}
        <div className="card-skeleton-body">
            <Skeleton paragraph={{ rows }} active loading title />
        </div>
    </div>
);

export default CardSkeleton;
