import { SearchPaginationQuery } from ".";
import api from "./apiClient";
import { ListResponse, User } from "./types";

export interface UserCreatePayload {
    organization: User["organization"];
    firstName: User["firstName"];
    lastName: User["lastName"];
    email: User["email"];
    role: User["role"];
}

export interface UserUpdatePayload extends Omit<User, "id"> {
    id?: User["id"];
}

export type UserListPayload = SearchPaginationQuery;
export type UserDetailsPayload = User["id"];
export type UserIdPayload = User["id"];

export const details = async (payload?: UserDetailsPayload) => {
    if (!payload) {
        throw new Error("missing id");
    }

    return await api.get<User>(`/users/${payload}`).then((response) => response?.data);
};

export const create = async (payload?: UserCreatePayload) => {
    return await api.post(`/users`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: UserUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<User>(`/users/${id}`, payload).then((response) => response?.data);
};

export const remove = async (id?: UserDetailsPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/users/${id}`).then((response) => response?.data);
};

export type UserslistResponse = ListResponse<User>;
export const list = async (payload: UserListPayload = {}) => {
    return await api.get<UserslistResponse>("/users", { params: payload }).then((response) => response?.data);
};
