import { FC } from "react";
import Popconfirm, { PopconfirmProps } from "antd/lib/popconfirm";

interface DeletePopConfirmProps extends Omit<PopconfirmProps, "title"> {
    title?: PopconfirmProps["title"];
    isLoading?: boolean;
}

const DeletePopConfirm: FC<DeletePopConfirmProps> = ({ children, isLoading, ...props }) => (
    <Popconfirm
        title="Confirmez-vous la suppression ?"
        okText="Confirmer"
        cancelText="Annuler"
        okButtonProps={{
            size: "small",
            danger: true,
            loading: isLoading,
        }}
        cancelButtonProps={{
            size: "small",
        }}
        {...props}
    >
        {children ?? "Supprimer"}
    </Popconfirm>
);

export default DeletePopConfirm;
