import { useState, VFC } from "react";
import { Select, SelectProps } from "antd";
import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { JobNameListPayload, JobNameListReponse, nameList } from "../../../queries/api/jobs";
import { AgendaJob } from "../../../queries/api/types";
import useQueryParams from "../../../hooks/queryParams";
import { useDebounce } from "../../../hooks";
import { defaultErrorMessage } from "../../../i18n";
import { errorMessage } from "../../../helpers/message";

interface JobsNameSelectProps {
    readOnly?: boolean;
    selectProps?: SelectProps<AgendaJob["id"]>;
    queryPayload?: JobNameListPayload;
}

let searchTimeout: number | NodeJS.Timeout;

const JobNameSelect: VFC<JobsNameSelectProps> = ({ selectProps }) => {
    const [queryParams, setQueryParams] = useQueryParams("jobsNames-list");
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 750);
    const name = queryParams.getAll("name") ?? undefined;
    const { data: jobsNames } = useQuery<JobNameListReponse, AxiosError>(
        ["jobsNames-list", debouncedSearch],
        async () =>
            await nameList({
                ...(debouncedSearch ? { search: debouncedSearch } : {}),
            }),
        {
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );
    const setNameParam = (value: string[]) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            setQueryParams({
                name: value ? value : undefined,
            });
            setSearch("");
        }, 750);
    };
    return (
        <div className="p-1">
            <Select<string | string[]>
                {...selectProps}
                placeholder={selectProps?.placeholder ?? "Selectionner un job"}
                allowClear
                options={jobsNames?.items.map((job) => {
                    return { value: job, label: job };
                })}
                onChange={(value) => setNameParam([...value])}
                onSearch={setSearch}
                showSearch
                mode="multiple"
                style={{ width: "150px" }}
                dropdownStyle={{ width: "100px" }}
                maxTagCount={3}
                dropdownMatchSelectWidth
                defaultValue={name}
                placement={"bottomRight"}
                filterOption={false}
            />
        </div>
    );
};

export default JobNameSelect;
