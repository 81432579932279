import { FC } from "react";
import { Layout } from "antd";

import "../assets/styles/Login.less";

const LoginLayout: FC = ({ children }) => (
    <Layout id="login-layout">
        <div id="login-section">
            <div id="login-section-inner">{children}</div>
        </div>
    </Layout>
);

export default LoginLayout;
