import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    create,
    details,
    list,
    ValueListCreatePayload,
    ValueListSlugPayload,
    ValueListListPayload,
    ValueListListResponse,
    ValueListUpdatePayload,
    remove,
    update,
} from "./api/valueLists";
import { ValueList } from "./api/types";

export const valueListsKeys = {
    all: ["valueLists"],
    lists: () => [...valueListsKeys.all, "list"],
    list: (params?: ValueListListPayload) => [...valueListsKeys.lists(), params],
    details: () => [...valueListsKeys.all, "details"],
    detail: (id?: ValueListSlugPayload) => [...valueListsKeys.details(), id],
};

export const useValueListList = <TData = ValueListListResponse>(
    params?: ValueListListPayload,
    options?: UseQueryOptions<ValueListListResponse, AxiosError, TData>
) => {
    return useQuery<ValueListListResponse, AxiosError, TData>(
        valueListsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useValueListDetails = <TData = ValueList>(
    id?: ValueListSlugPayload,
    options?: UseQueryOptions<ValueList, AxiosError, TData>
) => {
    return useQuery<ValueList, AxiosError, TData>(valueListsKeys.detail(id), async () => await details(id), options);
};

export const useValueListCreate = (options?: UseMutationOptions<ValueList, AxiosError, ValueListCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<ValueList, AxiosError, ValueListCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(valueListsKeys.lists());
        },
    });
};

export const useValueListUpdate = (options?: UseMutationOptions<ValueList, AxiosError, ValueListUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<ValueList, AxiosError, ValueListUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(valueListsKeys.detail(variables.slug));
            queryClient.invalidateQueries(valueListsKeys.lists());
        },
    });
};

export const useValueListRemove = (options?: UseMutationOptions<undefined, AxiosError, ValueListSlugPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, ValueListSlugPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(valueListsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(valueListsKeys.lists());
        },
    });
};
