import { useState, VFC } from "react";
import { Space, Tabs, Spin, Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { show } from "@ebay/nice-modal-react";

import Seo from "../../../components/Seo";
import LayoutHeader from "../../../components/LayoutHeader";
import ListTitle from "../../../components/ListTitle";
import { useValueListList, useValueListRemove } from "../../../queries/valueLists";
import ApiResult from "../../../components/ApiResult";
import ValueListForm from "../../../components/valueList/ValueListForm";
import ValueListFormDrawer from "../../../components/drawers/ValueListFormDrawer";
import DeletePopConfirm from "../../../components/DeletePopConfirm";

const ValueList: VFC = () => {
    const {
        data: valueLists,
        isLoading,
        isFetching,
        isError,
        error,
    } = useValueListList(undefined, {
        onSuccess: (data) => {
            if (!activeTabKey) {
                setActiveTabKey(data?.items?.[0]?.slug);
            }
        },
    });
    const { mutateAsync: deleteValueList, isLoading: isRemoving } = useValueListRemove();
    const [activeTabKey, setActiveTabKey] = useState(valueLists?.items?.[0]?.slug);

    return (
        <>
            <Seo title="Listes de valeurs" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={valueLists?.totalCount} className="mb-0">
                        Listes de valeurs
                    </ListTitle>
                    <Button type="primary" size="large" onClick={async () => await show(ValueListFormDrawer)}>
                        Ajouter une liste
                    </Button>
                </div>
            </LayoutHeader>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Spin spinning={isLoading || isFetching}>
                        <Tabs
                            activeKey={activeTabKey}
                            onChange={setActiveTabKey}
                            tabBarExtraContent={{
                                right: (
                                    <div className="space-x-3">
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={async () =>
                                                await show(ValueListFormDrawer, { valueListSlug: activeTabKey })
                                            }
                                        />
                                        <DeletePopConfirm
                                            onConfirm={async () => {
                                                await deleteValueList(activeTabKey ?? "");
                                                setActiveTabKey(undefined);
                                            }}
                                            isLoading={isRemoving}
                                        >
                                            <Button icon={<DeleteOutlined />} />
                                        </DeletePopConfirm>
                                    </div>
                                ),
                            }}
                        >
                            {valueLists?.items?.map((valueList) => (
                                <Tabs.TabPane key={valueList.slug} tab={valueList.title}>
                                    <ValueListForm valueListSlug={valueList.slug} queryParamsKey="valueList" />
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </Spin>
                )}
            </Space>
        </>
    );
};

export default ValueList;
