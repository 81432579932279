import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { ListResponse, Permission, AgendaJob } from "./types";
import { AgendaJobStatus } from "./types";

export interface JobUpdatePayload extends Partial<Omit<AgendaJob, "id">> {
    id: AgendaJob["id"];
}

export type JobIdPayload = AgendaJob["id"];

export type JobNameListPayload = SearchPaginationQuery & { status?: AgendaJobStatus[] } & {
    name?: AgendaJob["name"][];
};

export type JobListReponse = ListResponse<AgendaJob>;

export type JobNameListReponse = ListResponse<AgendaJob["name"]>;

export type RoleListPermissionsResponse = Permission[];

// Routes
export const list = async (payload?: JobNameListPayload) => {
    return await api.get<JobListReponse>(`/jobs`, { params: payload }).then((response) => response?.data);
};

export const nameList = async (payload?: JobNameListPayload) => {
    return await api.get<JobNameListReponse>(`/jobs/names`, { params: payload }).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: JobUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<AgendaJob>(`/jobs/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: JobIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.get<AgendaJob>(`/jobs/${id}`).then((response) => response?.data);
};

export const remove = async (id?: JobIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/jobs/${id}`).then((response) => response?.data);
};
