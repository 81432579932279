import { FC } from "react";

import "../../assets/styles/Applications.less";
import Seo from "../../components/Seo";
import ListTitle from "../../components/ListTitle";
import LayoutHeader from "../../components/LayoutHeader";

const RestoresList: FC = () => {
    return (
        <>
            <Seo title="Applications" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={0 /* applicationsData?.totalCount */} className="mb-0">
                        Restaurations
                    </ListTitle>
                </div>
            </LayoutHeader>
        </>
    );
};

export default RestoresList;
