import { useState, FC } from "react";
import Input from "antd/lib/input";
import { PasswordProps } from "antd/lib/input/Password";
import { CheckOutlined } from "@ant-design/icons";

import BasicList from "./BasicList";
import validatePasswordRules, { PasswordIssueReason } from "../helpers/passwords";
import { classNames } from "../helpers";
import constants from "../config/constants";

interface PasswordInputProps extends PasswordProps {
    value?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({ value, onChange, ...props }) => {
    const [val, setVal] = useState(value ?? "");
    const [errors, setErrors] = useState<ReturnType<typeof validatePasswordRules>>();
    const onChangeInput: PasswordProps["onChange"] = (e) => {
        const inputValue = e.target.value;
        setVal(inputValue);
        setErrors(validatePasswordRules(inputValue));
        if (typeof onChange === "function") {
            onChange(e);
        }
    };

    return (
        <>
            <Input.Password {...props} onChange={onChangeInput} />
            <BasicList className="password-input-instructions">
                <li>
                    <CheckOutlined
                        className={classNames(
                            !!val &&
                                !errors?.issues.find(
                                    (issue) =>
                                        issue.reason === PasswordIssueReason.minimumLength ||
                                        issue.reason === PasswordIssueReason.maximumLength
                                ) &&
                                "is-valid"
                        )}
                    />
                    {constants.PASSWORD_MINIMUM_LENGTH} caractères minimum
                </li>
                <li>
                    <CheckOutlined
                        className={classNames(
                            !!val &&
                                !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireNumber) &&
                                "is-valid"
                        )}
                    />
                    Au moins un chiffre
                </li>
                <li>
                    <CheckOutlined
                        className={classNames(
                            !!val &&
                                !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireCapital) &&
                                "is-valid"
                        )}
                    />
                    Au moins une lettre en majuscule
                </li>
                <li>
                    <CheckOutlined
                        className={classNames(
                            !!val &&
                                !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireSpecial) &&
                                "is-valid"
                        )}
                    />
                    Au moins un caractère spécial
                </li>
            </BasicList>
        </>
    );
};

export default PasswordInput;
