import { FC } from "react";
import dayjs from "dayjs";
import { ColumnProps } from "antd/lib/table";
import { show } from "@ebay/nice-modal-react";
import { Button, message, Popconfirm, Table, TableProps, Tooltip, Tag, Typography } from "antd";
import { DeleteOutlined, EditOutlined, ClockCircleOutlined, SaveTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import "../../assets/styles/Applications.less";

import Seo from "../../components/Seo";
import ListTitle from "../../components/ListTitle";
import LayoutHeader from "../../components/LayoutHeader";
import ApplicationFormDrawer from "../superAdmin/applications/ApplicationFormDrawer";
import { useApplicationRemove, useApplicationsList } from "../../queries/applications";
import { useBackupCreate } from "../../queries/backups";
import useQueryParams from "../../hooks/queryParams";
import { Application, BackupConfig, BackupSource, DeviceType } from "../../queries/api/types";
import { formatRelativeTime } from "../../i18n";

const ApplicationsList: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams("applications-list");
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const { data: applicationsData, isLoading, isFetching } = useApplicationsList({ page });
    const applicationRemove = useApplicationRemove({
        onError: (error) => {
            message.error(
                `Une erreur est survenue pendant la suppression l'application (${error.response?.status ?? "0"})`
            );
        },
        onSuccess: () => {
            message.success("Application supprimée avec succès");
        },
    });
    const backupNow = useBackupCreate({
        onError: (error) => {
            message.error(
                `Une erreur est survenue pendant la planification de la sauvegarde (${error.response?.status ?? "0"})`
            );
        },
        onSuccess: () => {
            message.success("Une sauvegarde à été planifiée avec succès");
        },
    });
    const onTableChange: TableProps<Application>["onChange"] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };
    const rowKey = (item: Application) => `${item.id}`;
    const onClickCreateApplication = () => {
        show(ApplicationFormDrawer);
    };
    // On Click Action For Application
    const onClickRemoveApplicationButton = (id: Application["id"]) => {
        applicationRemove.mutate({ id });
    };
    const onClickEditApplicationButton = (id: Application["id"]) => {
        show(ApplicationFormDrawer, { applicationId: id });
    };
    const onClickBackupNowButton = (application: Application["id"]) => {
        const source =
            (applicationsData?.items.find((a) => a.id === application)?.backupConfigs?.[0]?.source as BackupSource) ??
            BackupSource.mongodb;
        backupNow.mutate({
            application,
            source,
        });
    };
    const columns: Array<ColumnProps<Application>> = [
        {
            title: "Organization",
            key: "organization",
            render: (record: Application) => record.organization?.name,
        },
        {
            title: "Nom de l'application",
            key: "name",
            render: (record: Application) => <span className="font-semibold">{record.name}</span>,
        },
        {
            title: "Agents",
            key: "agents",
            render: (record: Application) =>
                record.applicationClients?.filter((a) => a.type === DeviceType.agent).length ?? 0,
        },
        {
            title: "Backups",
            dataIndex: "backupCount",
        },
        {
            title: "CRON",
            key: "cron",
            render: (record: Application) => {
                const backupConfigs = (record.backupConfigs ?? []).filter((c) => c.enabled);
                if (!backupConfigs[0]?.enabled) {
                    return "-";
                }
                return backupConfigs.map((backupConfig, index) => (
                    <Tag key={index} color="success" icon={<ClockCircleOutlined />}>
                        {backupConfig.frequency ?? "-"}
                    </Tag>
                ));
            },
        },
        {
            title: "Prochaine sauvegarde",
            key: "nextScheduledAt",
            render: (record: Application) => {
                const nextBackupConfig = (record.backupConfigs ?? []).reduce<BackupConfig | undefined>((res, c) => {
                    if (!c.enabled || !c.nextScheduledAt) {
                        return res;
                    }
                    if (res?.nextScheduledAt && dayjs(res.nextScheduledAt).isBefore(c.nextScheduledAt)) {
                        return res;
                    }
                    return c;
                }, undefined);

                return nextBackupConfig ? (
                    formatRelativeTime(nextBackupConfig.nextScheduledAt)
                ) : (
                    <Typography.Text disabled>Aucune</Typography.Text>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "id",
            width: "15%",
            render: (id: string, record: Application) => (
                <>
                    <Tooltip overlay="Sauvegarder maintenant">
                        <Popconfirm
                            placement="topLeft"
                            icon={<InfoCircleOutlined />}
                            title={"Une sauvegarde de application va être plannifié immédiatement"}
                            onConfirm={onClickBackupNowButton.bind(null, id)}
                            okText="Confirmer"
                            cancelText="Annuler"
                            disabled={
                                record.applicationClients?.filter((a) => a.type === DeviceType.agent).length === 0
                            }
                        >
                            <Button
                                className="actions-buttons"
                                shape="circle"
                                icon={<SaveTwoTone />}
                                disabled={
                                    record.applicationClients?.filter((a) => a.type === DeviceType.agent).length === 0
                                }
                            />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip overlay="Modifier">
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onClickEditApplicationButton.bind(null, id)}
                        />
                    </Tooltip>

                    <Tooltip overlay="Supprimer">
                        <Popconfirm
                            placement="topLeft"
                            title={"Êtes-vous sûr de supprimer cette application ?"}
                            onConfirm={onClickRemoveApplicationButton.bind(null, id)}
                            okText="Oui"
                            cancelText="Non"
                            disabled={record.applicationClients?.length !== 0}
                        >
                            <Button
                                className="actions-buttons"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                disabled={record.applicationClients?.length !== 0}
                            />
                        </Popconfirm>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <>
            <Seo title="Applications" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={applicationsData?.totalCount} className="mb-0">
                        Applications
                    </ListTitle>
                    <Button className="header-button" size="large" type="primary" onClick={onClickCreateApplication}>
                        Ajouter une application
                    </Button>
                </div>
            </LayoutHeader>

            <Table<Application>
                className="applications-list"
                columns={columns}
                rowKey={rowKey}
                loading={isLoading || isFetching}
                dataSource={applicationsData?.items}
                onChange={onTableChange}
                pagination={{
                    total: applicationsData?.totalCount,
                    current: page + 1,
                    pageSize: applicationsData?.pageSize,
                }}
            />
        </>
    );
};

export default ApplicationsList;
