import { FC, FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";

import "./assets/styles/App.less";

import ProtectedRoute from "./components/ProtectedRoute";
import MainLayout from "./components/MainLayout";
import CookieBanner from "./components/CookieBanner";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./pages/error";
import Login from "./pages/login";
// import Home from "./pages/home";
import Privacy from "./pages/privacy";
import UsersList from "./pages/usersList";
import { getRawRoute, RoutePathName } from "./routes";
import UserDetails from "./pages/userDetails";
import ForgottenPassword from "./pages/forgottenPassword";
import ResetPassword from "./pages/resetPassword";
import SuperAdminApplicationsList from "./pages/superAdmin/applications";
import CustomersList from "./pages/customers";
import ApplicationsList from "./pages/applications";
import BackupsList from "./pages/backups";
import RestoresList from "./pages/restores";
import AgentsList from "./pages/agents";
import { useLogs } from "./context/LogsContext";
import PermissionRoute, { PermissionRouteProps } from "./components/PermissionRoute";
import { Permission } from "./queries/api/types";
import ValueList from "./pages/superAdmin/valueList";
import RoleList from "./pages/superAdmin/roles";
import JobList from "./pages/superAdmin/jobs";
import OrganizationsList from "./pages/superAdmin/organizations";

const routes: Array<PermissionRouteProps & { Children: FunctionComponent }> = [
    {
        path: getRawRoute(RoutePathName.home),
        // Children: Home,
        Children: ApplicationsList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.usersDetails),
        permission: Permission.users,
        Children: UserDetails,
    },
    {
        path: getRawRoute(RoutePathName.usersList),
        permission: Permission.users,
        Children: UsersList,
    },
    {
        path: getRawRoute(RoutePathName.customerList),
        permission: Permission.organizations,
        Children: CustomersList,
    },
    {
        path: getRawRoute(RoutePathName.privacy),
        Children: Privacy,
    },
    {
        path: getRawRoute(RoutePathName.superAdmin_applicationList),
        permission: Permission.superAdmin,
        Children: SuperAdminApplicationsList,
    },
    {
        path: getRawRoute(RoutePathName.superAdmin_organizationList),
        permission: Permission.superAdmin,
        Children: OrganizationsList,
    },
    {
        path: getRawRoute(RoutePathName.superAdmin_valueListList),
        permission: Permission.superAdmin,
        Children: ValueList,
    },
    {
        path: getRawRoute(RoutePathName.superAdmin_roleList),
        permission: Permission.superAdmin,
        Children: RoleList,
    },
    {
        path: getRawRoute(RoutePathName.superAdmin_jobList),
        permission: Permission.superAdmin,
        Children: JobList,
    },
    {
        path: getRawRoute(RoutePathName.applicationsList),
        Children: ApplicationsList,
    },
    {
        path: getRawRoute(RoutePathName.backupsList),
        Children: BackupsList,
    },
    {
        path: getRawRoute(RoutePathName.restoresList),
        Children: RestoresList,
    },
    {
        path: getRawRoute(RoutePathName.agentsList),
        Children: AgentsList,
    },
];

const App: FC = () => {
    const { sendLogs } = useLogs();

    return (
        <Switch>
            <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
            <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
            <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
            <ProtectedRoute>
                <CookieBanner />
                <MainLayout>
                    <ErrorBoundary sendLogs={sendLogs}>
                        <Switch>
                            {routes.map(({ Children, ...props }) => (
                                <PermissionRoute
                                    key={
                                        Array.isArray(props.path)
                                            ? props.path.join("")
                                            : (props.path as string | undefined)
                                    }
                                    {...props}
                                >
                                    <Children />
                                </PermissionRoute>
                            ))}

                            <Route path="*">
                                <ErrorPage />
                            </Route>
                        </Switch>
                    </ErrorBoundary>
                </MainLayout>
            </ProtectedRoute>
        </Switch>
    );
};

export default App;
