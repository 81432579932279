import { VFC, ReactNode } from "react";
import { Button, Popconfirm } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

interface EditableCellActionsProps {
    isEditing: boolean;
    onEdit: () => void;
    onRemove?: () => void;
    onSave?: () => void;
    onCancel: () => void;
    loading?: boolean;
    removeTitle?: ReactNode;
}

const EditableCellActions: VFC<EditableCellActionsProps> = ({
    isEditing,
    onEdit,
    onRemove,
    onCancel,
    loading,
    removeTitle,
}) => {
    return (
        <div className="space-x-1">
            {!isEditing ? (
                <>
                    <Button key="edit" icon={<EditOutlined />} type="text" size="large" onClick={onEdit} />
                    {onRemove && (
                        <Popconfirm
                            title={removeTitle || "Êtes-vous sûr de vouloir supprimer cette valeur ?"}
                            okText="Supprimer"
                            cancelText="Annuler"
                            okButtonProps={{
                                danger: true,
                                shape: "round",
                                size: "small",
                            }}
                            cancelButtonProps={{
                                shape: "round",
                                size: "small",
                            }}
                            onConfirm={onRemove}
                            placement="topRight"
                            icon={false}
                        >
                            <Button icon={<DeleteOutlined />} type="text" size="large" />
                        </Popconfirm>
                    )}
                </>
            ) : (
                <>
                    <Button
                        icon={<CloseCircleOutlined />}
                        type="text"
                        size="large"
                        onClick={onCancel}
                        disabled={loading}
                        danger
                    />
                    <Button
                        key="save"
                        icon={<CheckCircleOutlined />}
                        type="text"
                        size="large"
                        className="btn-text-success"
                        htmlType="submit"
                        loading={loading}
                    />
                </>
            )}
        </div>
    );
};

export default EditableCellActions;
