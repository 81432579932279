import { Checkbox, Form, Input, message, Drawer, Select, Button, DrawerProps, Spin } from "antd";
import { FormItemProps, FormProps } from "antd/lib/form";
import NiceModal, { antdDrawer, useModal } from "@ebay/nice-modal-react";

import {
    useApplicationClientCreate,
    useApplicationClientDetails,
    useApplicationClientUpdate,
} from "../../../queries/applicationClients";
import { ApplicationClient, Application, DeviceType } from "../../../queries/api/types";
import { requiredRule } from "../../../helpers";

interface ApplicationClientFormDrawerProps extends Omit<DrawerProps, "visible" | "onClose">, Record<string, unknown> {
    applicationId: Application["id"];
    applicationClientId?: ApplicationClient["id"];
}

const ApplicationClientFormDrawer = NiceModal.create(
    ({ applicationId, applicationClientId, ...props }: ApplicationClientFormDrawerProps) => {
        const modal = useModal();
        const isEditing = applicationClientId !== undefined;
        const [form] = Form.useForm();
        const { isLoading: isLoadingDetails } = applicationClientId
            ? useApplicationClientDetails(
                  { applicationId, id: applicationClientId },
                  {
                      enabled: modal.visible && isEditing,
                      onSuccess: (data) => {
                          form.setFieldsValue(data);
                      },
                  }
              )
            : { isLoading: false };
        const applicationClientCreate = useApplicationClientCreate({
            onError: () => {
                message.error("Une erreur est survenue pendant la création de l'application client");
            },
            onSuccess: () => {
                modal.hide();
                message.success("L'application client a été créée");
            },
        });
        const applicationClientUpdate = useApplicationClientUpdate({
            onError: () => {
                message.error("Une erreur est survenue pendant la mise à jour de l'application client");
            },
            onSuccess: () => {
                modal.hide();
                message.success("L'application client a été mise à jour");
            },
        });
        const onSubmit: FormProps["onFinish"] = (values) => {
            const payload = {
                name: values.name,
                reference: values.reference,
                isPrivate: values.isPrivate ?? false,
                type: values.type,
                options: {
                    isBackoffice: values.isBackoffice,
                },
                application: applicationId ?? "",
                ...(values.type === DeviceType.ios
                    ? {
                          iosOptions: {
                              authentificationPush: values.authentificationPush,
                              keyId: values.keyId,
                              teamId: values.teamId,
                              bundleId: values.bundleId,
                              stagingServer: values.stagingServer ?? false,
                          },
                      }
                    : {}),
                ...(values.type === DeviceType.android
                    ? {
                          androidOptions: {
                              certificatPush: values.certificatPush,
                              bundleId: values.bundleId,
                          },
                      }
                    : {}),
            };

            if (isEditing) {
                applicationClientUpdate.mutate({ id: applicationClientId, ...payload });
            } else {
                applicationClientCreate.mutate(payload);
            }
        };

        // Device Type options
        const shouldUpdate: FormItemProps["shouldUpdate"] = (prevValues, currentValues) => {
            return prevValues.type !== currentValues.type;
        };

        const iosOptions = (
            <>
                <Form.Item
                    name={["iosOptions", "authentificationPush"]}
                    label="Clé d'authenfication de push Apple"
                    rules={[requiredRule]}
                >
                    <Input.TextArea rows={4} placeholder="Ex:" />
                </Form.Item>

                <Form.Item name={["iosOptions", "keyId"]} label="Key ID de Push Apple" rules={[requiredRule]}>
                    <Input />
                </Form.Item>

                <Form.Item name={["iosOptions", "teamId"]} label="Team ID de Push Apple" rules={[requiredRule]}>
                    <Input />
                </Form.Item>

                <Form.Item name={["iosOptions", "bundleId"]} label="Bundle ID iOS" rules={[requiredRule]}>
                    <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 24 }} name={["iosOptions", "stagingServer"]} valuePropName="checked">
                    <Checkbox>Utiliser le serveur de staging Apple</Checkbox>
                </Form.Item>
            </>
        );

        const androidOptions = (
            <>
                <Form.Item name="certificatPush" label="Certificat de remote push Android" rules={[requiredRule]}>
                    <Input.TextArea rows={4} placeholder="Ex:" />
                </Form.Item>

                <Form.Item name="bundleId" label="Bundle ID Android" rules={[requiredRule]}>
                    <Input />
                </Form.Item>
            </>
        );

        return (
            <>
                <Drawer
                    title={`${isEditing ? "Modification" : "Création"} d'une application client`}
                    width={560}
                    {...props}
                    {...antdDrawer(modal)}
                >
                    <Spin spinning={isLoadingDetails}>
                        <Form name="Création" form={form} onFinish={onSubmit} layout="vertical">
                            <Form.Item
                                label="Nom de l'application client"
                                name="name"
                                rules={[{ required: true, message: "Veuillez rentrer le nom de l'application !" }]}
                            >
                                <Input />
                            </Form.Item>

                            {!isEditing && (
                                <Form.Item
                                    label="Référence"
                                    name="reference"
                                    rules={[
                                        { required: true, message: "Veuillez rentrer la réference de l'application !" },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            )}

                            <Form.Item
                                label="Type d'appareil"
                                name="type"
                                rules={[{ required: true, message: "Veuillez séléctionner le type d'appareil !" }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Sélectionner l'appareil"
                                    defaultActiveFirstOption={false}
                                >
                                    {Object.values(DeviceType).map((deviceType) => (
                                        <Select.Option key={deviceType} value={deviceType}>
                                            {deviceType}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item noStyle shouldUpdate={shouldUpdate}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue("type") === DeviceType.ios) {
                                        return iosOptions;
                                    } else if (getFieldValue("type") === DeviceType.android) {
                                        return androidOptions;
                                    } else {
                                        return null;
                                    }
                                }}
                            </Form.Item>
                            {!isEditing && (
                                <>
                                    <Form.Item wrapperCol={{ span: 24 }} name="isPrivate" valuePropName="checked">
                                        <Checkbox>Is Private</Checkbox>
                                    </Form.Item>

                                    <Form.Item
                                        wrapperCol={{ span: 24 }}
                                        name={["options", "isBackoffice"]}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Is Back Office</Checkbox>
                                    </Form.Item>
                                </>
                            )}

                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={
                                    isEditing ? applicationClientUpdate.isLoading : applicationClientCreate.isLoading
                                }
                                block
                            >
                                {isEditing ? "Modifier l'application client" : "Créer l'application client"}
                            </Button>
                        </Form>
                    </Spin>
                </Drawer>
            </>
        );
    }
);

export default ApplicationClientFormDrawer;
