import { Button, Drawer, DrawerProps, Form, Input, Spin } from "antd";
import { FormProps } from "antd/lib/form";
import { antdDrawer, useModal, create } from "@ebay/nice-modal-react";

import { useOrganizationCreate, useOrganizationDetails, useOrganizationUpdate } from "../../queries/organizations";
import { Organization } from "../../queries/api/types";
import { errorMessage, successMessage } from "../../helpers/message";

interface CustomerFormDrawerProps extends DrawerProps, Record<string, any> {
    organizationId?: Organization["id"];
}

const CustomerFormDrawer = create<CustomerFormDrawerProps>(({ organizationId, ...props }) => {
    const modal = useModal();
    const isEditing = organizationId !== undefined;
    const [form] = Form.useForm();
    const { data: organization, isLoading: isLoadingDetails } = useOrganizationDetails(organizationId, {
        enabled: isEditing,
        onSuccess: (data) => {
            form.setFieldsValue(data);
        },
    });
    const { mutate: createOrganization, isLoading: isCreating } = useOrganizationCreate();
    const { mutate: updateOrganization, isLoading: isUpdating } = useOrganizationUpdate();

    const onSubmit: FormProps["onFinish"] = (values) => {
        if (isEditing) {
            createOrganization(
                { id: organizationId, ...values },
                {
                    onError: () => {
                        errorMessage({ content: "Une erreur est survenue pendant la mise à jour du client" });
                    },
                    onSuccess: () => {
                        successMessage({ content: "Client mis à jour avec succès" });
                        modal.hide();
                    },
                }
            );
        } else {
            updateOrganization(values, {
                onError: () => {
                    errorMessage({ content: "Une erreur est survenue pendant la création du client" });
                },
                onSuccess: () => {
                    successMessage({ content: "Client créé avec succès" });
                    modal.hide();
                },
            });
        }
    };

    return (
        <Drawer
            title={isEditing ? "Modifier le client" : "Créer un client"}
            width={420}
            {...props}
            {...antdDrawer(modal)}
        >
            <Spin spinning={isLoadingDetails}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    initialValues={isEditing ? organization : undefined}
                >
                    <Form.Item
                        label="Nom du client"
                        name="name"
                        rules={[{ required: true, message: "Veuillez rentrer le nom du client !" }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" size="large" htmlType="submit" loading={isCreating || isUpdating} block>
                            {isEditing ? "Modifier le client" : "Créer le client"}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    );
});

export default CustomerFormDrawer;
