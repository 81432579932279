import { useState } from "react";
import { Form, Input, message, Drawer, Select, Button, DrawerProps, Collapse, Spin, Typography } from "antd";
import { FormProps } from "antd/lib/form";
import { CaretRightOutlined, DatabaseOutlined } from "@ant-design/icons";
import NiceModal, { antdDrawer, useModal } from "@ebay/nice-modal-react";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

import { Application } from "../../../queries/api/types";
import { requiredRule } from "../../../helpers";
import { useOrganizationsList } from "../../../queries/organizations";
import { useApplicationCreate, useApplicationDetails, useApplicationUpdate } from "../../../queries/applications";
import BackupConfigsFormItem from "../../../components/forms/BackupConfigsFormItem";

interface ApplicationFormDrawerProps extends Omit<DrawerProps, "visible" | "onClose">, Record<string, unknown> {
    applicationId?: Application["id"];
}

const ApplicationFormDrawer = NiceModal.create(({ applicationId, ...props }: ApplicationFormDrawerProps) => {
    const modal = useModal();
    const isEditing = applicationId !== undefined;
    const [form] = Form.useForm();
    const [organizationSearch, setOrganizationSearch] = useState<string>();
    const { data: applicationDetails, isLoading: isLoadingDetails } = applicationId
        ? useApplicationDetails(
              { id: applicationId },
              {
                  enabled: modal.visible && isEditing,
                  onSuccess: (data) => {
                      form.setFieldsValue(data);
                  },
              }
          )
        : { isLoading: false, data: undefined };
    const {
        data: organizationsData,
        isLoading,
        isFetching,
    } = useOrganizationsList({
        search: organizationSearch ?? undefined,
    });
    const applicationCreate = useApplicationCreate({
        onError: () => {
            message.error("Une erreur est survenue pendant la création de l'application");
        },
        onSuccess: () => {
            modal.hide();
            message.success("L'application a été créée");
        },
    });
    const applicationUpdate = useApplicationUpdate({
        onError: () => {
            message.error("Une erreur est survenue pendant la mise à jour de l'application");
        },
        onSuccess: () => {
            modal.hide();
            message.success("L'application a été mise à jour");
        },
    });
    const onSubmit: FormProps["onFinish"] = (values) => {
        if (isEditing) {
            applicationUpdate.mutate({
                id: applicationId,
                name: values.name,
                backupConfigs: values.backupConfigs,
                storageConfig: values.storageConfig?.type ? values.storageConfig : undefined,
            });
        } else {
            applicationCreate.mutate({
                organization: values.organization,
                name: values.name,
                backupConfigs: values.backupConfigs,
                storageConfig: values.storageConfig?.type ? values.storageConfig : undefined,
            });
        }
    };

    const collapseStyle = {
        marginBottom: 24,
        borderRadius: 8,
        border: "none",
    };

    return (
        <>
            <Drawer
                title={`${isEditing ? "Modification" : "Création"} d'une application`}
                width={700}
                {...props}
                {...antdDrawer(modal)}
            >
                <Spin spinning={isLoadingDetails}>
                    <Form
                        form={form}
                        onFinish={onSubmit}
                        layout="vertical"
                        initialValues={
                            isEditing && applicationDetails
                                ? { name: applicationDetails.name, backupConfigs: applicationDetails.backupConfigs }
                                : undefined
                        }
                    >
                        <Form.Item label="Nom de l'application" name="name" rules={[requiredRule]}>
                            <Input />
                        </Form.Item>

                        {/* <BackupConfigsFormItem form={form} backupConfigs={applicationDetails?.backupConfigs} /> */}

                        {!isEditing && (
                            <Form.Item label="Organisation" name="organization" rules={[requiredRule]}>
                                <Select
                                    showSearch
                                    placeholder="Sélectionner une organisation"
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    onSearch={setOrganizationSearch}
                                    loading={isLoading || isFetching}
                                >
                                    {organizationsData?.items.map((organization) => (
                                        <Select.Option key={organization.id} value={organization.id}>
                                            {organization.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Collapse
                            bordered={false}
                            defaultActiveKey={["1", "2"]}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            style={collapseStyle}
                        >
                            <BackupConfigsFormItem
                                key="1"
                                form={form}
                                backupConfigs={applicationDetails?.backupConfigs}
                            />
                            <Collapse.Panel
                                header={
                                    <b>
                                        <DatabaseOutlined /> Configuration du stockage des sauvegardes
                                    </b>
                                }
                                key="2"
                            >
                                <Form.Item
                                    label={
                                        <Title level={5}>
                                            Type de stockage <Text type="secondary">(par défaut)</Text>
                                        </Title>
                                    }
                                    name={["storageConfig", "type"]}
                                >
                                    <Select placeholder="Sélectionner un type">
                                        <Select.Option key="none" value="none">
                                            <Typography.Text disabled>Par défaut</Typography.Text>
                                        </Select.Option>
                                        <Select.Option key="minio" value="minio">
                                            Minio
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label="URL" name={["storageConfig", "endpoint"]}>
                                    <Input />
                                </Form.Item>
                            </Collapse.Panel>
                        </Collapse>

                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={isEditing ? applicationUpdate.isLoading : applicationCreate.isLoading}
                            block
                        >
                            {isEditing ? "Modifier l'application" : "Créer l'application"}
                        </Button>
                    </Form>
                </Spin>
            </Drawer>
        </>
    );
});

export default ApplicationFormDrawer;
