import { FC } from "react";
import { Card, Descriptions, Result, Button, Space, message } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useParams, useHistory } from "react-router-dom";
import { show } from "@ebay/nice-modal-react";

import { useUserDetails, useUserRemove } from "../../queries/users";
import Seo from "../../components/Seo";
import CardSkeleton from "../../components/CardSkeleton";
import LayoutHeader from "../../components/LayoutHeader";
import { getFullName } from "../../helpers";
import { getRoute, RoutePathName } from "../../routes";
import DeletePopConfirm from "../../components/DeletePopConfirm";
import { User } from "../../queries/api/types";
import UserFormDrawer from "../usersList/UserFormDrawer";

const UserDetails: FC = () => {
    const { userId } = useParams<{ userId?: string }>();
    const { data: userData, isLoading, isError, error } = useUserDetails(userId);
    const history = useHistory();

    const { mutate: removeUser, isLoading: isRemoving } = useUserRemove();

    const onClickBackButton = () => {
        history.push(getRoute(RoutePathName.usersList), { restoreQueryParams: true });
    };

    const onClickRemoveUser = (id: string) => {
        removeUser(id, {
            onError: () => {
                message.error("Une erreur est survenu lors de la suppression de l'utilisateur");
            },
            onSuccess: () => {
                message.success("L'utilisateur à été supprimé avec succès");
                onClickBackButton();
            },
        });
    };
    const onClickUpdateUserDetails = (id: User["id"]) => {
        show(UserFormDrawer, { userId: id });
    };

    return (
        <>
            <Seo title={getFullName(userData)} />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <PageHeader onBack={onClickBackButton} title={getFullName(userData)} />
                    <Space size="middle">
                        <Button
                            className="header-button"
                            size="large"
                            type="primary"
                            onClick={() => onClickUpdateUserDetails(userId!)}
                        >
                            Modifier
                        </Button>
                        <DeletePopConfirm isLoading={isRemoving} onConfirm={() => onClickRemoveUser(userId!)}>
                            <Button className="header-button" size="large" type="primary">
                                Supprimer
                            </Button>
                        </DeletePopConfirm>
                    </Space>
                </div>
            </LayoutHeader>
            {isLoading && <CardSkeleton rows={6} />}
            {isError ? (
                <Result status={error?.request?.status} />
            ) : (
                <Card>
                    <Descriptions column={1} bordered>
                        {Object.entries(userData ?? {}).map(([key, value]) => (
                            <Descriptions.Item label={key} key={key}>
                                {JSON.stringify(value)}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Card>
            )}
        </>
    );
};

export default UserDetails;
