export default Object.freeze({
    PAGE_SIZE: 50,
    API: Object.freeze({
        BASE_URL: import.meta.env.VITE_API_BASE_URL || "/api",
        KEY: import.meta.env.VITE_API_KEY || "",
    }),
    SEO: Object.freeze({
        BASE_URL: "",
        SITE_NAME: "Front-end boilerplate",
        DEFAULT_TITLE: "Front-end boilerplate",
        DEFAULT_DESCRIPTION: "Boilerplate for all developments based on reactjs, ant design, react-query...",
    }),
    HEADER_PRODUCT_NAME: "",
    GOOGLE_ANALYTICS: import.meta.env.VITE_GOOGLE_ANALYTICS || "",
    ENABLE_DEBUG_LOGS: false, // import.meta.env.PROD',
    DEBUG_LOGS_APP: "", // <app>_<service>
    VERSION: import.meta.env.VITE_VERSION || "",
    FACEBOOK_APP_ID: import.meta.env.VITE_FACEBOOK_APP_ID || "",
    NOTIFICATIONS_REFETCH_INTERVAL_IN_MS: 5000,
    PASSWORD_MINIMUM_LENGTH: 10,
});
