import { FC } from "react";
import { Checkbox, Form } from "antd";

import { Permission, PermissionRight } from "../../../queries/api/types";

interface RoleListCheckboxProps {
    permissionName: Permission | "inputField";
    permissionRight: PermissionRight;
    disabled?: boolean;
}

const RoleListCheckbox: FC<RoleListCheckboxProps> = ({ disabled, permissionName, permissionRight }) => {
    const formInstance = Form.useFormInstance();
    const isDisabled = permissionName === "inputField" && disabled;

    const onChange = () => {
        const permissions = formInstance.getFieldValue("permissions");

        formInstance.setFieldsValue({
            permissions: {
                [permissionName]: {
                    [PermissionRight.read]: false,
                    [PermissionRight.write]: false,
                    [PermissionRight.disabled]: false,
                    [permissionRight]: permissions[permissionName][permissionRight],
                },
            },
        });
    };

    return (
        <Form.Item noStyle name={["permissions", permissionName, permissionRight]} valuePropName="checked">
            <Checkbox onChange={onChange} disabled={isDisabled} />
        </Form.Item>
    );
};

export default RoleListCheckbox;
