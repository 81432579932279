import { FC, ReactNode } from "react";
import { Redirect, Link, useLocation } from "react-router-dom";
import { FormProps } from "antd/lib/form/Form";
import { Form, Input, Button, Alert, Spin, Divider } from "antd";
import { GitlabFilled } from "@ant-design/icons";

import logo from "../../assets/images/logo.svg";
import LoginLayout from "../../components/LoginLayout";
import Seo from "../../components/Seo";
import { getRoute, RoutePathName } from "../../routes";
import { useAuth } from "../../context/AuthContext";
import ButtonLink from "../../components/ButtonLink";

const Login: FC = () => {
    const { from } = useLocation<{ from?: Location }>().state ?? {};
    const { user, isCheckingSession, login, checkSessionError } = useAuth();
    const { isLoading, mutate: sendLogin, isError, error } = login;
    const onFormValidSubmit: FormProps["onFinish"] = (values) => {
        sendLogin(values);
    };

    let errorMessage: ReactNode = isError
        ? "Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur"
        : null;

    if (isError) {
        switch (error?.response?.status) {
            case 401:
                errorMessage = "Identifiant ou mot de passe incorrect";
                break;

            case 403:
                errorMessage = "Non autorisé";
                break;

            case 404:
                errorMessage = "Nom d'utilisateur introuvable";
                break;

            case 409:
                errorMessage = (
                    <>
                        Un compte existe déjà avec cette adresse email, avez-vous{" "}
                        <Link to={getRoute(RoutePathName.forgottenPassword)}>oublié votre mot de passe ?</Link>
                    </>
                );
                break;

            default:
                errorMessage = "Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur";
                break;
        }
    }

    if (user && !checkSessionError) {
        return <Redirect to={from ?? getRoute(RoutePathName.home)} />;
    }

    return (
        <LoginLayout>
            <Seo title="Connexion" />
            <Spin spinning={isCheckingSession}>
                <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                    <img className="logo" src={logo} alt="logo" />

                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: "Veuillez renseigner votre e-mail" }]}
                    >
                        <Input size="large" placeholder="Nom d'utilisateur" />
                    </Form.Item>

                    <Form.Item
                        className="password-row"
                        name="password"
                        rules={[{ required: true, message: "Veuillez renseigner votre mot de passe" }]}
                    >
                        <Input.Password size="large" placeholder="Mot de passe" />
                    </Form.Item>

                    {errorMessage ? (
                        <Alert type="error" message={errorMessage} style={{ marginBottom: "1.5rem" }} />
                    ) : null}

                    <Form.Item>
                        <Button type="primary" size="large" block htmlType="submit" loading={isLoading}>
                            Connexion
                        </Button>
                    </Form.Item>

                    <Link to={getRoute(RoutePathName.forgottenPassword)}>Mot de passe oublié ?</Link>

                    <Divider>ou</Divider>

                    <ButtonLink href="/api/auth/sso" size="large" icon={<GitlabFilled />} block>
                        Gitlab
                    </ButtonLink>
                </Form>
            </Spin>
        </LoginLayout>
    );
};

export default Login;
