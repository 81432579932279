import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { SearchPaginationQuery } from "./api";
import { list, details, create, BackupCreatePayload, BackupIdPayload, remove, BackupListResponse } from "./api/backups";
import { Backup } from "./api/types";

export const backupKeys = {
    all: ["backups"],
    lists: () => [...backupKeys.all, "list"],
    list: (params?: SearchPaginationQuery) => [...backupKeys.lists(), params],
    details: () => [...backupKeys.all, "details"],
    detail: (params: BackupIdPayload) => [...backupKeys.details(), params],
    creates: () => [...backupKeys.all, "creates"],
    create: (params: BackupCreatePayload) => [...backupKeys.creates(), params],
};

// LIST
export const useBackupsList = (params?: SearchPaginationQuery) => {
    return useQuery<BackupListResponse, AxiosError>(backupKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
    });
};

// CREATE
export const useBackupCreate = (options: UseMutationOptions<Backup, AxiosError, BackupCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Backup, AxiosError, BackupCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options.onSuccess?.(data, variables, context);

            // invalidate list query since we deleted the item
            queryClient.invalidateQueries(backupKeys.lists());

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(backupKeys.detail({ id: data.id }));
        },
    });
};

// DETAILS
export const useBackupDetails = <TData = Backup>(
    params: BackupIdPayload,
    options: UseQueryOptions<Backup, AxiosError, TData>
) => {
    return useQuery<Backup, AxiosError, TData>(backupKeys.detail(params), async () => await details(params), options);
};

// DELETE
export const useBackupRemove = (options: UseMutationOptions<undefined, AxiosError, BackupIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, BackupIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options.onSuccess?.(data, variables, context);

            // invalidate list query since we deleted the item
            queryClient.invalidateQueries(backupKeys.lists());

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(backupKeys.detail(variables));
        },
    });
};
