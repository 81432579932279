import { Drawer, DrawerProps, Spin } from "antd";
import NiceModal, { antdDrawer, useModal } from "@ebay/nice-modal-react";

import { useJobDetails } from "../../../queries/jobs";
import { AgendaJob } from "../../../queries/api/types";

export interface JobFormDrawerProps extends Omit<DrawerProps, "visible" | "onClose"> {
    jobId?: AgendaJob["id"];
}

const JobFormDrawerProps = NiceModal.create(({ jobId, ...props }: JobFormDrawerProps) => {
    const modal = useModal();
    const isEditing = jobId !== undefined;
    const { isLoading: isLoadingDetails, data: jobDetails } = useJobDetails(jobId, {
        enabled: modal.visible && isEditing,
    });

    return (
        <Drawer title={jobDetails?.name} width={500} {...props} {...antdDrawer(modal)}>
            <Spin spinning={isLoadingDetails}>
                <pre>{JSON.stringify(jobDetails, null, 2)}</pre>
            </Spin>
        </Drawer>
    );
});

export default JobFormDrawerProps;
