import { FC, useState, useCallback } from "react";
import { useCookies as useReactCookies } from "react-cookie";
import { Typography, Switch, Button, Tag } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import {
    acceptAllCookies,
    refuseAllCookies,
    setCookiesOptions,
    removeGoogleTagManager,
    addGoogleTagManager,
} from "../../helpers/cookies";
import Seo from "../../components/Seo";
import { useCookies, CookieStateName, Cookie } from "../../context/CookiesContext";

const Privacy: FC = () => {
    const { acceptAll, refuse, toggle, gtag } = useCookies();
    const [_, setCookie, removeCookie] = useReactCookies(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [showSuccess, setShowSuccess] = useState(false);
    const onToggle = (cookie: CookieStateName) => {
        toggle(cookie);

        if (cookie === CookieStateName.gtag) {
            if (gtag) {
                removeGoogleTagManager(removeCookie);
                setCookie(Cookie.gtag, 0, setCookiesOptions);
            } else {
                addGoogleTagManager();
                setCookie(Cookie.gtag, 1, setCookiesOptions);
            }
        }
    };
    const onClickAccept = useCallback(() => {
        acceptAllCookies(setCookie);
        acceptAll();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [acceptAll, setCookie]);
    const onClickRefuse = useCallback(() => {
        refuseAllCookies(setCookie, removeCookie);
        refuse();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [refuse, removeCookie, setCookie]);

    return (
        <div className="container page">
            <Seo title="Politique de confidentialité et Protection de la vie privée" />
            <Typography.Title level={1}>Politique de confidentialité et Protection de la vie privée</Typography.Title>
            <Typography.Title level={2} id="cookies">
                Choix des cookies
            </Typography.Title>

            <Typography.Title level={3}>Optionnels</Typography.Title>
            <ul>
                <li className="flex">
                    <Switch checked={!!gtag} onChange={onToggle.bind(null, CookieStateName.gtag)} />
                    <p>
                        Cookies de Google Analytics destinés à réaliser des statistiques de navigation et de
                        fréquentation
                        <code>ezee-gtag, _gat, _gid, _ga</code>
                    </p>
                </li>
            </ul>
            <Typography.Title level={3}>Obligatoires</Typography.Title>
            <ul>
                <li className="flex">
                    <Switch checked disabled />
                    <p>
                        Cookie permettant de déterminer si l&rsquo;utilisateur a accepté ou refusé un ou plusieurs
                        cookies
                        <code>ezee-has_made_choice</code>
                    </p>
                </li>
            </ul>
            <div className="flex">
                <Button type="ghost" onClick={onClickRefuse}>
                    Tout refuser
                </Button>
                <Button type="primary" onClick={onClickAccept}>
                    Tout accepter
                </Button>
                {showSuccess && (
                    <div>
                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Enregistré
                        </Tag>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Privacy;
