import { VFC } from "react";

import { ValueList } from "../../queries/api/types";
import ValueListAddCard from "./ValueListAddCard";
import ValueListTable from "./ValueListTable";

interface ValueListFormProps {
    valueListSlug: ValueList["slug"];
    queryParamsKey: string;
}

const ValueListForm: VFC<ValueListFormProps> = ({ valueListSlug, queryParamsKey }) => {
    return (
        <div className="flex space-x-6">
            <div style={{ width: 375, maxWidth: "100%" }}>
                <ValueListAddCard valueListSlug={valueListSlug} />
            </div>
            <div className="flex-1">
                <ValueListTable valueListSlug={valueListSlug} queryParamsKey={queryParamsKey} />
            </div>
        </div>
    );
};

export default ValueListForm;
