import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import {
    details,
    list,
    nameList,
    remove,
    JobIdPayload,
    JobNameListPayload,
    JobListReponse,
    JobNameListReponse,
    JobUpdatePayload,
    update,
} from "./api/jobs";
import { AgendaJob } from "./api/types";

export const jobKeys = {
    all: ["jobs"],
    lists: () => [...jobKeys.all, "list"],
    list: (params?: JobNameListPayload) => [...jobKeys.lists(), params],
    details: () => [...jobKeys.all, "details"],
    detail: (id?: JobIdPayload) => [...jobKeys.details(), id],
};

export const useJobList = <TData = JobListReponse>(
    params: JobNameListPayload | undefined,
    options?: UseQueryOptions<JobListReponse, AxiosError, TData>
) => {
    return useQuery<JobListReponse, AxiosError, TData>(jobKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useJobNameList = <TData = JobNameListReponse>(
    params: JobNameListPayload | undefined,
    options?: UseQueryOptions<JobNameListReponse, AxiosError, TData>
) => {
    return useQuery<JobNameListReponse, AxiosError, TData>(jobKeys.list(params), async () => await nameList(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useJobDetails = <TData = AgendaJob>(
    id: JobIdPayload | undefined,
    options?: UseQueryOptions<AgendaJob, AxiosError, TData>
) => {
    return useQuery<AgendaJob, AxiosError, TData>(jobKeys.detail(id), async () => await details(id), options);
};

export const useJobUpdate = (options?: UseMutationOptions<AgendaJob, AxiosError, JobUpdatePayload>) => {
    const queryClient = useQueryClient();
    return useMutation<AgendaJob, AxiosError, JobUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate application list query to refetch with the newly added item
            queryClient.invalidateQueries(jobKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(jobKeys.detail(variables.id));
        },
    });
};

export const useJobRemove = (options?: UseMutationOptions<undefined, AxiosError, JobIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, JobIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(jobKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(jobKeys.lists());
        },
    });
};
