import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormProps } from "antd/lib/form/Form";
import { Form, Input, Button, Alert, Typography } from "antd";

import logo from "../../assets/images/logo.svg";
import Seo from "../../components/Seo";
import LoginLayout from "../../components/LoginLayout";
import { getRoute, RoutePathName } from "../../routes";
import { useAuth } from "../../context/AuthContext";
import { ForgottenPasswordPayload } from "../../queries/api/auth";
import { requiredRule } from "../../helpers";

const ForgottenPassword: FC = () => {
    const { forgottenPassword } = useAuth();
    const { mutate: sendForgottenPassword, error, isSuccess, isLoading } = forgottenPassword;
    const [email, setEmail] = useState<string | undefined>();
    const onFormValidSubmit: FormProps["onFinish"] = (values: ForgottenPasswordPayload) => {
        setEmail(values.username);
        sendForgottenPassword(values);
    };

    let errorMessage = error ? "Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard." : null;

    if (error?.response?.status === 404) {
        errorMessage = "Adresse e-mail non trouvée.";
    }

    useEffect(
        () => () => {
            setEmail(undefined);
        },
        [setEmail]
    );

    return (
        <LoginLayout>
            <Seo title="Mot de passe oublié" />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                <img className="logo" src={logo} alt="logo" />
                <Typography.Title level={1}>Mot de passe oublié</Typography.Title>

                {isSuccess ? (
                    <Typography.Paragraph>
                        <p>
                            Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe à l’adresse{" "}
                            {email}.<br />
                            <br />
                            Merci de surveiller vos e-mails.
                        </p>
                    </Typography.Paragraph>
                ) : (
                    <>
                        <Typography.Paragraph>
                            <p>
                                Saisissez l’adresse e-mail associée à votre compte, nous vous enverrons un mail avec un
                                lien pour réinitialiser votre mot de passe.
                            </p>
                        </Typography.Paragraph>
                        <Form.Item
                            name="username"
                            rules={[
                                requiredRule,
                                {
                                    type: "email",
                                    message: "Adresse e-mail non valide",
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input placeholder="Adresse e-mail" />
                        </Form.Item>
                        {errorMessage && <Alert type="error" message={errorMessage} className="mb-24" />}
                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" loading={isLoading} block>
                                Réinitialiser votre mot de passe
                            </Button>
                        </Form.Item>
                    </>
                )}
                <Link to={getRoute(RoutePathName.login)}>Revenir à l’écran de connexion</Link>
            </Form>
        </LoginLayout>
    );
};

export default ForgottenPassword;
