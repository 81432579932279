import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { setDefaultBreakpoints, BreakpointProvider } from "react-socks";
import { ConfigProvider as AntdConfigProvider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import NiceModal from "@ebay/nice-modal-react";
// locale configuration when not using internationalisation
import fr_FR from "antd/lib/locale/fr_FR";
import dayjs from "dayjs";
import dayjsFr from "dayjs/locale/fr.js";

import "./assets/styles/tailwind.css";
import "./assets/styles/AntOverride.less";

import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { CookiesProvider } from "./context/CookiesContext";
import { LogsProvider } from "./context/LogsContext";

dayjs.locale(dayjsFr);

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([{ xs: 0 }, { sm: 480 }, { md: 576 }, { lg: 768 }, { xl: 992 }, { xxl: 1200 }, { xxxl: 1600 }]);

// API mock data service worker
if (import.meta.env.DEV) {
    const { worker } = await import("./mocks/browser");
    worker.start();
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 20 * 1000, // 20s cache
        },
    },
});

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <LogsProvider>
                <AuthProvider>
                    <CookiesProvider>
                        <BrowserRouter>
                            <BreakpointProvider>
                                <AntdConfigProvider locale={fr_FR}>
                                    <NiceModal.Provider>
                                        <Component />
                                    </NiceModal.Provider>
                                </AntdConfigProvider>
                            </BreakpointProvider>
                        </BrowserRouter>
                    </CookiesProvider>
                </AuthProvider>
            </LogsProvider>
        </QueryClientProvider>,
        document.getElementById("root") as HTMLElement
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import("intersection-observer");
        }
        if (!window.URLSearchParams) {
            await import("url-search-params-polyfill");
        }
    };

    await checkPolyfills();

    render(App);
};

init();
