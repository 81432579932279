import { Layout } from "antd";
import { FC } from "react";

import { LayoutProvider } from "../context/LayoutContext";
import Header from "./Header";
import MainMenu from "./MainMenu";

const MainLayout: FC = ({ children }) => (
    <LayoutProvider>
        <Layout id="main-layout">
            <Header />
            <Layout>
                <MainMenu />
                <Layout.Content id="main-content">{children}</Layout.Content>
            </Layout>
        </Layout>
    </LayoutProvider>
);

export default MainLayout;
