import { Form, FormItemProps, Select, SelectProps } from "antd";
import { AxiosError } from "axios";
import { FC, ReactNode, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { errorMessage } from "../../helpers/message";
import { useDebounce } from "../../hooks";
import { defaultErrorMessage } from "../../i18n";
import { list, RoleListPayload, RoleListReponse } from "../../queries/api/roles";
import { Role } from "../../queries/api/types";

interface RoleFormItemProps extends FormItemProps {
    selectRolesProps?: SelectProps<Role["id"]>;
    queryPayload?: RoleListPayload;
    readOnly?: boolean;
    optionLabelRender?: (record: Role) => ReactNode;
    defaultRoles?: Role[];
}
const RoleFormItem: FC<RoleFormItemProps> = ({
    readOnly,
    selectRolesProps,
    queryPayload,
    optionLabelRender = (record) => record.name,
    defaultRoles = [],
    ...props
}) => {
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);

    const { data: role, isLoading } = useQuery<RoleListReponse, AxiosError>(
        ["roles", debouncedSearch, queryPayload],
        async () =>
            await list({
                ...(debouncedSearch ? { search: debouncedSearch } : {}),
                ...queryPayload,
            }),
        {
            enabled: !readOnly,
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );

    return (
        <>
            <Form.Item label={"Role"} {...props}>
                <Select
                    {...selectRolesProps}
                    placeholder={selectRolesProps?.placeholder ?? "Choisir un role"}
                    filterOption={false}
                    onSearch={setSearch}
                    loading={isLoading}
                    options={defaultRoles
                        .concat(role?.items?.filter((rle) => !defaultRoles.find((def) => def.id === rle.id)) ?? [])
                        .map((rle) => ({
                            label: optionLabelRender(rle),
                            value: rle.id,
                        }))}
                    showSearch
                />
            </Form.Item>
        </>
    );
};
export default RoleFormItem;
