import { Cron, DefaultLocale } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import cronParser from "cron-parser";
import { VFC, useState } from "react";
import {
    Form,
    Input,
    Button,
    Collapse,
    Space,
    Switch,
    FormInstance,
    Divider,
    FormListFieldData,
    Select,
    Typography,
} from "antd";
import {
    BuildOutlined,
    CaretRightOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    RightOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";

import { formatRelativeTime } from "../../i18n";
import { Application, BackupSource, UnitOfTime } from "../../queries/api/types";

type ValidateStatus = Parameters<typeof Form.Item>[0]["validateStatus"];

const FRENCH_LOCALE: DefaultLocale = {
    everyText: "Chaque",
    emptyMonths: "tous les mois",
    emptyMonthDays: "tous les jours du mois",
    emptyMonthDaysShort: "jour du mois",
    emptyWeekDays: "tous les jours de la semaine",
    emptyWeekDaysShort: "tous les jours de la semaine",
    emptyHours: "toutes les heures",
    emptyMinutes: "toutes les minutes",
    emptyMinutesForHourPeriod: "toutes",
    yearOption: "année",
    monthOption: "mois",
    weekOption: "semaine",
    dayOption: "jour",
    hourOption: "heure",
    minuteOption: "minute",
    rebootOption: "redémarrage",
    prefixPeriod: "Chaque",
    prefixMonths: "en",
    prefixMonthDays: "le",
    prefixWeekDays: "les",
    prefixWeekDaysForMonthAndYearPeriod: "uniquement les",
    prefixHours: "à",
    prefixMinutes: "h",
    prefixMinutesForHourPeriod: "à",
    suffixMinutesForHourPeriod: "minute(s)",
    errorInvalidCron: "Format CRON Invalide",
    clearButtonText: "Effacer",
    weekDays: [
        // Order is important, the index will be used as value
        "Dimanche", // Sunday must always be first, it's "0"
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
    ],
    months: [
        // Order is important, the index will be used as value
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ],
    // Order is important, the index will be used as value
    altWeekDays: [
        "Dim", // Sunday must always be first, it's "0"
        "Lun",
        "Mar",
        "Mer",
        "Jeu",
        "Ven",
        "Sam",
    ],
    // Order is important, the index will be used as value
    altMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BackupConfigsFormItemProps {
    form: FormInstance;
    backupConfigs: Application["backupConfigs"];
    panelKey?: string;
}

const BackupConfigsFormItem: VFC<BackupConfigsFormItemProps> = ({ form, backupConfigs, ...props }) => {
    const validateCRONInput = (
        enabled: boolean,
        inputValue?: string
    ): {
        validateStatus: ValidateStatus;
        errorMsg: string | null;
    } => {
        try {
            if (!inputValue && !enabled) {
                return {
                    validateStatus: undefined,
                    errorMsg: null,
                };
            }
            if (!inputValue) {
                return {
                    validateStatus: enabled ? "error" : undefined,
                    errorMsg: null, // "Le champ ne peux pas être vide",
                };
            }
            const interval = cronParser.parseExpression(inputValue, {
                tz: "Europe/Paris",
            });
            return {
                validateStatus: "success",
                errorMsg: `Prochaine sauvegarde ${formatRelativeTime(interval.next().toDate())}`,
            };
        } catch (err) {
            return {
                validateStatus: enabled ? "error" : undefined,
                errorMsg: "Format CRON invalide",
            };
        }
    };

    const [cronInput, setCronInput] = useState<
        {
            value?: string;
            validateStatus?: ValidateStatus;
            errorMsg?: string | null;
        }[]
    >(
        backupConfigs?.map((backupConfig) => ({
            ...validateCRONInput(backupConfig.enabled ?? false, backupConfig.frequency),
            value: backupConfig.frequency,
        })) || []
    );
    const onCRONChange = (fieldIndex: number, value: string) => {
        const backupConfigsEnabled = form.getFieldValue(["backupConfigs", fieldIndex, "enabled"]);
        const cronData = cronInput.map((backupConfig, index) =>
            index === fieldIndex
                ? {
                      ...validateCRONInput(backupConfigsEnabled, value),
                      value,
                  }
                : backupConfig
        );
        if (cronInput.length <= fieldIndex) {
            cronData.push({
                ...validateCRONInput(backupConfigsEnabled, value),
                value,
            });
        }
        setCronInput(cronData);
        form.setFieldValue(["backupConfigs", fieldIndex, "frequency"], value);
    };
    const onActivationChange = (fieldIndex: number) => {
        const CRONValue = form.getFieldValue(["backupConfigs", fieldIndex, "frequency"]);
        onCRONChange(fieldIndex, CRONValue);
    };

    const collapseStyle = {
        marginBottom: 24,
        borderRadius: 8,
        border: "none",
    };

    const header = (
        <b>
            <ClockCircleOutlined /> Configuration des sauvegardes automatiques
        </b>
    );

    const advancedForm = (field: FormListFieldData) => (
        <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />}
            style={collapseStyle}
        >
            <Collapse.Panel
                key="1"
                header={
                    <>
                        <BuildOutlined /> Avancé
                    </>
                }
            >
                <Title level={5}>
                    Rétention <Text type="secondary">(les deux conditions sont vérifiées)</Text>
                </Title>
                <Space align="baseline" size="large">
                    <Form.Item style={{ marginBottom: 0 }} name={[field.name, "retention", "duration"]}>
                        <Input
                            type="number"
                            placeholder="10"
                            min={1}
                            addonAfter={
                                <Form.Item noStyle name={[field.name, "retention", "durationUnit"]}>
                                    <Select style={{ minWidth: 105 }}>
                                        <Select.Option value={UnitOfTime.days}>jours</Select.Option>
                                        <Select.Option value={UnitOfTime.weeks}>semaines</Select.Option>
                                        <Select.Option value={UnitOfTime.months}>mois</Select.Option>
                                        <Select.Option value={UnitOfTime.years}>années</Select.Option>
                                    </Select>
                                </Form.Item>
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        name={[field.name, "retention", "minCount"]}
                        // help={`${form.getFieldValue(["backupConfigs", field.name, "name"]) ?? "<name>"}_<date>`}
                    >
                        <Input type="number" placeholder="1" min={1} addonAfter="sauvegardes" />
                    </Form.Item>
                </Space>
                {/* <Form.Item
                    label="Rétention"
                    name={[field.name, "retention"]}
                    // help={`${form.getFieldValue(["backupConfigs", field.name, "name"]) ?? "<name>"}_<date>`}
                >
                    <Input
                        type="number"
                        defaultValue="10"
                        addonBefore={
                            <Select defaultValue="number">
                                <Select.Option value="number">Nombre</Select.Option>
                                <Select.Option value="time">Pendant</Select.Option>
                            </Select>
                        }
                        addonAfter={
                            <Select defaultValue="days" style={{ minWidth: 105 }}>
                                <Select.Option value="days">jours</Select.Option>
                                <Select.Option value="weeks">semaines</Select.Option>
                                <Select.Option value="months">mois</Select.Option>
                                <Select.Option value="years">années</Select.Option>
                            </Select>
                        }
                    />
                </Form.Item> */}
                <Divider />
                <Form.Item
                    label={<Title level={5}>Type de Stockage</Title>}
                    name={[field.name, "storageConfig", "type"]}
                >
                    <Select placeholder="Sélectionner un type">
                        <Select.Option key="none" value="none">
                            <Typography.Text disabled>Par défaut</Typography.Text>
                        </Select.Option>
                        <Select.Option key="minio" value="minio">
                            Minio
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item label="URL" name={[field.name, "storageConfig", "endpoint"]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Prefix de fichier"
                    name={[field.name, "name"]}
                    // help={`${form.getFieldValue(["backupConfigs", field.name, "name"]) ?? "<name>"}_<date>`}
                >
                    <Input placeholder='"dump"' />
                </Form.Item>
            </Collapse.Panel>
        </Collapse>
    );

    const formList = (
        <Form.List name="backupConfigs">
            {(fields, { add, remove }) => (
                <>
                    {fields.map((field, index) => (
                        <>
                            <Form.Item label="Type/source de sauvegarde" name={[field.name, "source"]}>
                                <Select placeholder="Sélectionner une source" defaultValue={BackupSource.mongodb}>
                                    {/* <Select.Option key="none" value="none">
                                        <Typography.Text disabled>Par défaut</Typography.Text>
                                    </Select.Option> */}
                                    {Object.values(BackupSource).map((source) => (
                                        <Select.Option key={source} value={source}>
                                            {source}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Space key={field.key} align="baseline" size="large">
                                <Form.Item name={[field.name, "enabled"]} valuePropName="checked">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        onChange={() => onActivationChange(field.name)}
                                    />
                                </Form.Item>

                                <Cron
                                    clearButton={false}
                                    humanizeLabels={true}
                                    locale={FRENCH_LOCALE}
                                    value={
                                        cronInput?.[index]?.value ?? backupConfigs?.[index]?.frequency ?? "* * * * *"
                                    }
                                    setValue={(value: string) => onCRONChange(index, value)}
                                />

                                <Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Form.Item>
                            </Space>
                            {advancedForm(field)}
                            {index === fields.length - 1 ? undefined : <Divider dashed />}
                        </>
                    ))}
                    <Form.Item style={{ marginTop: 16, marginBottom: 0 }}>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Plannifier une nouvelle sauvegarde
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );

    const collapsableFormList = (
        <Collapse.Panel {...props} key={props.panelKey ?? "1"} header={header}>
            {formList}
        </Collapse.Panel>
    );

    return props.panelKey ? (
        collapsableFormList
    ) : (
        <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={collapseStyle}
        >
            {collapsableFormList}
        </Collapse>
    );
};

export default BackupConfigsFormItem;
