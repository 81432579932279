import { FC } from "react";
import { ColumnProps } from "antd/lib/table";
import { Table, TableProps, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "../../assets/styles/Agents.less";

import Seo from "../../components/Seo";
import ListTitle from "../../components/ListTitle";
import LayoutHeader from "../../components/LayoutHeader";
import { useApplicationClientsList } from "../../queries/applicationClients";
import useQueryParams from "../../hooks/queryParams";
import { ApplicationClient } from "../../queries/api/types";
import { formatRelativeTime } from "../../i18n";

const AgentsList: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams("Agents-list");
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const { data: AgentsData, isLoading, isFetching } = useApplicationClientsList({ page });
    const onTableChange: TableProps<ApplicationClient>["onChange"] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };
    const rowKey = (item: ApplicationClient) => `${item.id}`;
    const columns: Array<ColumnProps<ApplicationClient>> = [
        {
            title: "Nom de l'agent",
            key: "name",
            render: (record: ApplicationClient) => {
                const agentInfo: Record<string, string | undefined> = {
                    Id: record.id,
                    "Instance Id": record.agentId,
                    Version: record.agentVersion,
                };
                const agentInfoKeys = Object.keys(agentInfo).filter((key) => agentInfo[key]);
                return (
                    <>
                        {agentInfoKeys.length > 0 ? (
                            <Popover
                                title={record.name}
                                content={
                                    <table>
                                        <tbody>
                                            {agentInfoKeys.map((key) => (
                                                <tr key={key}>
                                                    <td className="font-semibold">{key}:</td>
                                                    <td className="pl-2">
                                                        <pre>{agentInfo[key]}</pre>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            >
                                <span className="font-semibold">{record.name}</span>
                                <InfoCircleOutlined style={{ color: "#808080" }} className="ml-2" />
                            </Popover>
                        ) : (
                            <span className="font-semibold">{record.name}</span>
                        )}
                    </>
                );
            },
        },
        {
            title: "Nom de l'application",
            key: "applicationName",
            render: (record: ApplicationClient) => record.application?.name || "-",
        },
        {
            title: "Backups",
            dataIndex: "backupCount",
        },
        {
            title: "Dernière connexion",
            render: (record: ApplicationClient) => formatRelativeTime(record.lastSeenAt),
        },
    ];

    return (
        <>
            <Seo title="Agents" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={AgentsData?.totalCount} className="mb-0">
                        Agents
                    </ListTitle>
                </div>
            </LayoutHeader>

            <Table<ApplicationClient>
                className="Agents-list"
                columns={columns}
                rowKey={rowKey}
                loading={isLoading || isFetching}
                dataSource={AgentsData?.items}
                onChange={onTableChange}
                pagination={{
                    total: AgentsData?.totalCount,
                    current: page + 1,
                    pageSize: AgentsData?.pageSize,
                }}
            />
        </>
    );
};

export default AgentsList;
