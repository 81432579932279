import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { Organization, ListResponse } from "./types";

// Controller Interface
export interface OrganizationCreatePayload {
    name: Organization["name"];
}

export interface OrganizationUpdatePayload extends Omit<Organization, "id"> {
    id?: Organization["id"];
}

export type OrganizationIdPayload = Organization["id"];

export type OrganizationListPayload = SearchPaginationQuery;
export type OrganizationListResponse = ListResponse<Organization>;

// Routes
export const list = async (payload?: OrganizationListPayload) => {
    return await api
        .get<OrganizationListResponse>(`/organizations`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: OrganizationCreatePayload) => {
    return await api.post(`/organizations`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: OrganizationUpdatePayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.put<Organization>(`/organizations/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: OrganizationIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.get<Organization>(`/organizations/${id}`).then((response) => response?.data);
};

export const remove = async (id?: OrganizationIdPayload) => {
    if (!id) {
        throw new Error("missing id");
    }
    return await api.delete<undefined>(`/organizations/${id}`).then((response) => response?.data);
};
