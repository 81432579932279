import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { ColumnProps, TableProps } from "antd/lib/table";
import { Table, Tag, Space, Result, Input, InputProps, Button, Menu, Dropdown, message } from "antd";
import { EditOutlined, EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { show, useModal } from "@ebay/nice-modal-react";

import { useUserRemove, useUsersList } from "../../queries/users";
import { User } from "../../queries/api/types";
import Seo from "../../components/Seo";
import LayoutHeader from "../../components/LayoutHeader";
import ListTitle from "../../components/ListTitle";
import useQueryParams from "../../hooks/queryParams";
import { getFullName } from "../../helpers";
import { getRoute, RoutePathName } from "../../routes";
import ConfirmationModal from "../../components/ConfirmationModal";
import UserFormDrawer from "./UserFormDrawer";

let searchTimeout: number;
const userConfirmationModalId = "confirmation-modal";

const UsersList: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams("users-list");
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const search = queryParams.get("search") ?? undefined;
    const { data: usersData, isLoading, isFetching, isError, error } = useUsersList({ page, search });

    const userModal = useModal(userConfirmationModalId);

    const userRemove = useUserRemove({
        onError: () => {
            message.error("Une erreur est survenu lors de la suppression de l'utilisateur");
        },
        onSuccess: () => {
            message.success("L'utilisateur à été supprimé avec succès");
            userModal.hide();
        },
    });
    const onTableChange: TableProps<User>["onChange"] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };

    const columns: Array<ColumnProps<User>> = [
        {
            key: "name",
            title: "Full name",
            render: (item) => (
                <Link to={getRoute(RoutePathName.usersDetails, { userId: item.id })}>{getFullName(item)}</Link>
            ),
        },
        {
            dataIndex: "email",
            title: "Email",
        },
        {
            dataIndex: ["role", "name"],
            title: "Role",
            render: (value) => {
                return <Tag>{value}</Tag>;
            },
        },
        {
            title: "Actions",
            key: "action",
            width: 128,
            render: (_, item) => (
                <Space size="middle">
                    <Button
                        onClick={() => onClickUpdateUser(item.id)}
                        icon={<EditOutlined />}
                        shape={"circle"}
                        className="actions-buttons"
                    />
                    <Dropdown overlay={getMenuDropdown(item.id)} trigger={["click"]} placement={"bottomRight"}>
                        <Button onClick={(e) => e.preventDefault()} shape={"circle"} icon={<EllipsisOutlined />} />
                    </Dropdown>
                </Space>
            ),
        },
    ];

    const logAsUser = (id: string) => () => {
        return window.location.replace(`/api/auth/logAs/${id}`);
    };

    const onClickAdduser = () => {
        show(UserFormDrawer);
    };

    const onClickUpdateUser = (id: User["id"]) => {
        show(UserFormDrawer, { userId: id });
    };

    const onClickRemoveUser = (id: User["id"]) => {
        userRemove.mutate(id);
    };

    const getMenuDropdown = (id: string) => {
        const menuDropdown = (
            <Menu
                items={[
                    {
                        label: "Se connecter en tant que",
                        key: "0",
                        onClick: logAsUser(id),
                    },
                    {
                        label: "Supprimer l'utilisateur",
                        key: "1",
                        onClick: () => showConfirmModal(id),
                    },
                ]}
            />
        );
        return menuDropdown;
    };
    const onSearch: InputProps["onChange"] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );

    const showConfirmModal = (userId: string) => {
        userModal.show({ userId: userId, onConfirmation: () => onClickRemoveUser(userId) });
    };

    return (
        <>
            <Seo title="Utilisateurs" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={usersData?.totalCount}>Utilisateurs</ListTitle>
                    <Button className="header-button" size="large" type="primary" onClick={onClickAdduser}>
                        Ajouter un utilisateur
                    </Button>
                </div>
            </LayoutHeader>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Input
                    placeholder="Rechercher un nom..."
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<SearchOutlined className="text-primary text-base leading-4" />}
                    size="large"
                    allowClear
                />
                {isError ? (
                    <Result status={error?.request?.status} />
                ) : (
                    <Table<User>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={usersData?.items}
                        pagination={{
                            total: usersData?.totalCount,
                            current: page + 1,
                            pageSize: usersData?.pageSize,
                        }}
                        onChange={onTableChange}
                    />
                )}
                <ConfirmationModal
                    text={"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}
                    id={userConfirmationModalId}
                    isLoading={userRemove.isLoading}
                />
            </Space>
        </>
    );
};

export default UsersList;
