import { AxiosError } from "axios";
import { UseMutationOptions, useQuery, useQueryClient, useMutation, UseQueryOptions } from "@tanstack/react-query";

import { SearchPaginationQuery } from "../queries/api";
import { User } from "./api/types";
import {
    details,
    list,
    remove,
    UserDetailsPayload,
    UserslistResponse,
    UserIdPayload,
    UserCreatePayload,
    create,
    UserUpdatePayload,
    update,
} from "./api/users";

export const userKeys = {
    all: ["users"],
    lists: () => [...userKeys.all, "list"],
    list: (params?: SearchPaginationQuery) => [...userKeys.lists(), params],
    details: () => [...userKeys.all, "details"],
    detail: (id?: UserDetailsPayload) => [...userKeys.details(), id],
};

export const useUsersList = (params?: SearchPaginationQuery) => {
    return useQuery<UserslistResponse, AxiosError>(userKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
    });
};

export const useUserDetails = <TData = User>(
    id: UserIdPayload | undefined,
    options?: UseQueryOptions<User, AxiosError, TData>
) => {
    return useQuery<User, AxiosError, TData>(userKeys.detail(id), async () => await details(id), options);
};
export const useUserCreate = (options?: UseMutationOptions<User, AxiosError, UserCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<User, AxiosError, UserCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(userKeys.lists());
        },
    });
};

export const useUserUpdate = (options?: UseMutationOptions<User, AxiosError, UserUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<User, AxiosError, UserUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(userKeys.detail(variables.id));

            queryClient.invalidateQueries(userKeys.lists());
        },
    });
};

export const useUserRemove = (options?: UseMutationOptions<undefined, AxiosError, UserIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, UserIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(userKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(userKeys.lists());
        },
    });
};
