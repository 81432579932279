import { SearchOutlined } from "@ant-design/icons";
import { Card, Form, FormInstance, FormProps, Input, Table } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import { useState, VFC } from "react";

import { errorMessage, successMessage } from "../../helpers/message";
import { useDebounce } from "../../hooks";
import useQueryParams from "../../hooks/queryParams";
import { defaultErrorMessageWithStatus, translateUnit } from "../../i18n";
import { ValueList, ValueListItem } from "../../queries/api/types";
import { useValueListItemList, useValueListItemRemove, useValueListItemUpdate } from "../../queries/valueListItems";
import { useValueListDetails } from "../../queries/valueLists";
import ApiResult from "../ApiResult";
import EditableCell from "../EditableCell";
import EditableCellActions from "../EditableCellActions";

interface ValueListTableProps {
    valueListSlug: ValueList["slug"];
    queryParamsKey: string;
}

const ValueListTable: VFC<ValueListTableProps> = ({ valueListSlug, queryParamsKey }) => {
    const [queryParams, setQueryParams] = useQueryParams(queryParamsKey);
    const [form] = Form.useForm();
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const [, setFormErrors] = useState<ReturnType<FormInstance["getFieldsError"]>>();
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const sort = queryParams.get("sort") || undefined;
    const sortOrder = queryParams.get("sortOrder") || undefined;
    const {
        data: valueList,
        isSuccess: valueListIsSuccess,
        isLoading: valueListIsLoading,
        isFetching: valueListIsFetching,
        isError: valueListIsError,
        error: valueListError,
    } = useValueListDetails(valueListSlug);
    const {
        data: valueListItem,
        isSuccess: valueListItemIsSuccess,
        isLoading: valueListItemIsLoading,
        isFetching: valueListItemIsFetching,
        isError: valueListItemIsError,
        error: valueListItemError,
    } = useValueListItemList({ valueListSlug, page, sort, sortOrder, search: debouncedSearch });
    const isSuccess = valueListIsSuccess && valueListItemIsSuccess;
    const isLoading = valueListIsLoading || valueListItemIsLoading;
    const isFetching = valueListIsFetching || valueListItemIsFetching;
    const isError = valueListIsError || valueListItemIsError;
    const error = valueListError || valueListItemError;
    const { mutate: updateValueListItem, isLoading: isUpdating } = useValueListItemUpdate();
    const { mutate: removeValueListItem, isLoading: isRemoving } = useValueListItemRemove();
    const fields = valueList?.fields;
    let columns: ColumnsType<ValueListItem> | undefined = fields?.map((field) => ({
        title: field.columnTitle || field.title,
        key: field.sortKey || field.title,
        sorter: field.isSortable,
        dataIndex: ["fields", field.name],
        render: (record) =>
            field.unit ? (
                <>
                    {record} {translateUnit(field.unit)}
                </>
            ) : (
                record
            ),
        onCell: (record) => ({
            form,
            // record,
            valueType: field.valueType,
            name: ["fields", field.name],
            title: field.columnTitle || field.title,
            required: field.isRequired,
            // translation: true,
            editing: isEditing(record),
        }),
    }));
    const onTableChange: TableProps<ValueListItem>["onChange"] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };
    const [editingId, setEditingId] = useState<ValueListItem["id"]>();
    const isEditing = (record: ValueListItem) => record.id === editingId;
    const editItem = (record: ValueListItem) => () => {
        form.resetFields();
        form.setFieldsValue({ ...record });
        setEditingId(record.id);
    };

    const cancelItem = () => {
        setEditingId(undefined);
    };

    const onFormFinish: FormProps["onFinish"] = (values) => {
        updateValueListItem(
            {
                id: editingId,
                valueListSlug,
                title: "dummy",
                meta: {},
                ...values,
            },
            {
                onSuccess: () => {
                    successMessage({ content: "Valeur ajoutée avec succès" });
                    setEditingId(undefined);
                },
                onError: (error) => {
                    errorMessage({ content: defaultErrorMessageWithStatus(error.response?.status) });
                },
            }
        );
    };

    const removeItem = (record: ValueListItem) => () => {
        removeValueListItem({ valueListSlug: valueList?.slug, id: record.id });
    };

    const onFormValuesChange = () => {
        setFormErrors(form.getFieldsError());
    };

    const onFormError = () => {
        setFormErrors(form.getFieldsError());
    };

    if (isError) {
        return <ApiResult status={error?.response?.status} />;
    }

    if (columns) {
        columns = columns.concat([
            {
                title: "Actions",
                width: 128,
                render: (_, valueListItem) => (
                    <EditableCellActions
                        isEditing={isEditing(valueListItem)}
                        onEdit={editItem(valueListItem)}
                        onRemove={removeItem(valueListItem)}
                        onCancel={cancelItem}
                        loading={isUpdating || isRemoving}
                    />
                ),
            },
        ]);
    }

    return isSuccess && !columns ? (
        <ApiResult status={404} />
    ) : (
        <Card>
            <Input
                placeholder="Rechercher une valeur"
                onChange={(e) => setSearch(e.target.value?.length ? e.target.value : undefined)}
                defaultValue={search}
                suffix={<SearchOutlined className="text-xl" />}
                className="mb-6"
                allowClear
            />
            <Form form={form} onFinishFailed={onFormError} onFinish={onFormFinish} onValuesChange={onFormValuesChange}>
                <Table<ValueListItem>
                    columns={columns}
                    className="-ml-6 -mr-6 -mb-6 !max-w-[calc(100%_+_3rem)]"
                    rowKey="id"
                    loading={isLoading || isFetching}
                    dataSource={valueListItem?.items}
                    onChange={onTableChange}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    pagination={{
                        total: valueListItem?.totalCount,
                        current: page + 1,
                        pageSize: valueListItem?.pageSize,
                        hideOnSinglePage: true,
                    }}
                />
            </Form>
        </Card>
    );
};

export default ValueListTable;
