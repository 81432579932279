import { FC } from "react";
import { ColumnProps } from "antd/lib/table";
import { Button, Popover, Table, TableProps, Tag } from "antd";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { show } from "@ebay/nice-modal-react";

import "../../assets/styles/Applications.less";
import Seo from "../../components/Seo";
import ListTitle from "../../components/ListTitle";
import LayoutHeader from "../../components/LayoutHeader";
import ApplicationFormDrawer from "../superAdmin/applications/ApplicationFormDrawer";
import { useBackupsList } from "../../queries/backups";
import useQueryParams from "../../hooks/queryParams";
import { Backup, BackupStatus } from "../../queries/api/types";
import { formatRelativeTime } from "../../i18n";

const BackupStatusTag: FC<{ backup: Backup }> = ({ backup }) => {
    switch (backup.status) {
        case BackupStatus.pending:
            return (
                <Tag icon={<ClockCircleOutlined />} color="default">
                    Planifiée
                </Tag>
            );
        case BackupStatus.assigned:
            return (
                <Tag icon={<MinusCircleOutlined />} color="default">
                    En attente
                </Tag>
            );
        case BackupStatus.running:
            return (
                <Tag icon={<SyncOutlined spin />} color="processing">
                    En cours
                </Tag>
            );
        case BackupStatus.finished:
            return (
                <Tag icon={<ExclamationCircleOutlined />} color="warning">
                    Terminé (Upload en attente)
                </Tag>
            );
        case BackupStatus.uploading:
            return (
                <Tag icon={<SyncOutlined spin />} color="processing">
                    Upload en cours ({backup.progress ?? 0} %)
                </Tag>
            );
        case BackupStatus.uploaded:
            return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                    Uploadé
                </Tag>
            );
        case BackupStatus.failed:
            return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                    Erreur
                </Tag>
            );
        default:
            return <Tag>{backup.status}</Tag>;
    }
};

const BackupsList: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams("backups-list");
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const {
        data: backupssData,
        isLoading,
        isFetching,
    } = useBackupsList({
        page,
        sort: "runAt",
        sortOrder: "asc",
    });
    const onTableChange: TableProps<Backup>["onChange"] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };
    const rowKey = (item: Backup) => `${item.id}`;
    const onClickCreateBackup = () => {
        show(ApplicationFormDrawer);
    };
    // On Click Action For Application
    const columns: Array<ColumnProps<Backup>> = [
        {
            title: "Status",
            render: (record: Backup) => {
                return (
                    <>
                        {record.error ? (
                            <Popover title={"Error:"} content={<pre>{record.error}</pre>}>
                                <BackupStatusTag backup={record} />
                                <InfoCircleOutlined style={{ color: "#808080" }} />
                            </Popover>
                        ) : (
                            <BackupStatusTag backup={record} />
                        )}
                    </>
                );
            },
        },
        {
            title: "Nom",
            key: "name",
            render: (record: Backup) => record.config?.name || "-",
        },
        {
            title: "Application",
            key: "applicationName",
            render: (record: Backup) =>
                record.application?.name ? <span className="font-semibold">{record.application?.name}</span> : "-",
        },
        {
            title: "Source",
            render: (record: Backup) => <Tag>{record.source}</Tag>,
        },
        {
            title: "Agent",
            key: "agentName",
            render: (record: Backup) => record.agent?.name || "-",
        },
        {
            title: "Date",
            render: (record: Backup) => formatRelativeTime(record.runAt),
        },
    ];

    return (
        <>
            <Seo title="Backups" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={backupssData?.totalCount} className="mb-0">
                        Sauvegardes
                    </ListTitle>
                    <Button className="header-button" size="large" type="primary" onClick={onClickCreateBackup}>
                        Planifier une sauvegarde
                    </Button>
                </div>
            </LayoutHeader>

            <Table<Backup>
                className="backups-list"
                columns={columns}
                rowKey={rowKey}
                loading={isLoading || isFetching}
                dataSource={backupssData?.items}
                onChange={onTableChange}
                pagination={{
                    total: backupssData?.totalCount,
                    current: page + 1,
                    pageSize: backupssData?.pageSize,
                }}
            />
        </>
    );
};

export default BackupsList;
