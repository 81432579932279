import { Button, Card, Drawer, DrawerProps, Form, FormProps, Input, InputNumber, Select, Spin, Switch } from "antd";
import { antdDrawer, useModal, create } from "@ebay/nice-modal-react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { defaultErrorMessage } from "../../i18n";
import { errorMessage, successMessage } from "../../helpers/message";
import { requiredRule } from "../../helpers";
import { useValueListCreate, useValueListDetails, useValueListUpdate } from "../../queries/valueLists";
import { ValueList, ValueListFieldUnit, ValueListFieldValueType } from "../../queries/api/types";

type Props = DrawerProps &
    Record<string, any> & {
        valueListSlug?: ValueList["slug"];
    };

const ValueListFormDrawer = create<Props>(({ valueListSlug, ...props }) => {
    const modal = useModal();
    const [form] = Form.useForm();
    const { mutate: createValueList, isLoading: isCreating } = useValueListCreate();
    const { mutate: updateValueList, isLoading: isUpdating } = useValueListUpdate();
    const isLoading = isCreating || isUpdating;
    const isEditing = !!valueListSlug;
    const { data: valueListDetails, isLoading: isLoadingDetails } = useValueListDetails(valueListSlug, {
        enabled: modal.visible && isEditing,
        onSuccess: (data) => {
            form.setFieldsValue(data);
        },
    });
    const onFormValidSubmit: FormProps<ValueList>["onFinish"] = (values) => {
        if (isEditing) {
            updateValueList(
                {
                    ...values,
                    routeSlug: valueListSlug,
                    fields: values.fields.map((field) => ({
                        ...field,
                        minValue: field.minValue || undefined,
                        maxValue: field.maxValue || undefined,
                        columnTitle: field.columnTitle || undefined,
                    })),
                    meta: {},
                },
                {
                    onSuccess: () => {
                        modal.hide();
                        successMessage({
                            content: "Liste de valeur modifiée avec succès",
                        });
                    },
                    onError: (error) => {
                        errorMessage({
                            content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
                        });
                    },
                }
            );
        } else {
            createValueList(
                {
                    ...values,
                    fields: values.fields.map((field) => ({
                        ...field,
                        minValue: field.minValue || undefined,
                        maxValue: field.maxValue || undefined,
                        columnTitle: field.columnTitle || undefined,
                    })),
                    meta: {},
                },
                {
                    onSuccess: () => {
                        modal.hide();
                        successMessage({
                            content: "Liste de valeur créée avec succès",
                        });
                    },
                    onError: (error) => {
                        errorMessage({
                            content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
                        });
                    },
                }
            );
        }
    };

    return (
        <Drawer
            width={600}
            title={`${isEditing ? "Modifier" : "Ajouter"} une liste de valeurs`}
            footer={
                <Button type="primary" size="large" onClick={() => form.submit()} loading={isLoading} block>
                    {isEditing ? "Modifier" : "Ajouter"}
                </Button>
            }
            {...props}
            {...antdDrawer(modal)}
        >
            <Spin spinning={isLoadingDetails}>
                <Form<ValueList>
                    form={form}
                    onFinish={onFormValidSubmit}
                    layout="vertical"
                    initialValues={
                        isEditing
                            ? valueListDetails
                            : {
                                  fields: [{}],
                              }
                    }
                    scrollToFirstError
                >
                    <Form.Item label="Titre de la liste" name="title" rules={[requiredRule]}>
                        <Input placeholder="Saisir un titre" />
                    </Form.Item>
                    <Form.Item label="Slug" name="slug" rules={[requiredRule]}>
                        <Input placeholder="Saisir un slug" />
                    </Form.Item>
                    <Form.List name="fields">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Card
                                        className="mb-4"
                                        headStyle={{ backgroundColor: "#f5f5f5" }}
                                        bodyStyle={{ backgroundColor: "#fafafa" }}
                                        size="small"
                                        key={field.key}
                                        title={`Champ n°${index + 1}`}
                                        extra={
                                            <Button
                                                className="btn-delete"
                                                type="text"
                                                icon={<DeleteOutlined />}
                                                size="small"
                                                onClick={remove.bind(null, index)}
                                            />
                                        }
                                    >
                                        <Form.Item
                                            label="Titre du champ"
                                            name={[field.name, "title"]}
                                            rules={[requiredRule]}
                                        >
                                            <Input placeholder="Saisir un titre" />
                                        </Form.Item>

                                        <Form.Item label="Name" name={[field.name, "name"]} rules={[requiredRule]}>
                                            <Input placeholder="Saisir un name" />
                                        </Form.Item>

                                        <Form.Item label="Nom de colonne" name={[field.name, "columnTitle"]}>
                                            <Input placeholder="Saisir un nom de colonne" />
                                        </Form.Item>

                                        <div className="flex gap-4 w-full">
                                            <div className="basis-1/2">
                                                <label htmlFor="isSortable">Triable</label>
                                                <Form.Item name={[field.name, "isSortable"]} valuePropName="checked">
                                                    <Switch />
                                                </Form.Item>
                                            </div>
                                            <div className="basis-1/2">
                                                <label htmlFor="isRequired">Champ obligatoire</label>
                                                <Form.Item name={[field.name, "isRequired"]} valuePropName="checked">
                                                    <Switch />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <Form.Item shouldUpdate noStyle>
                                            {({ getFieldValue }) =>
                                                getFieldValue(["fields", field.name, "isSortable"]) && (
                                                    <Form.Item label="Clé de sort" name={[field.name, "sortKey"]}>
                                                        <Input placeholder="Saisir une valeur" />
                                                    </Form.Item>
                                                )
                                            }
                                        </Form.Item>

                                        <Form.Item
                                            label="Type de valeur"
                                            name={[field.name, "valueType"]}
                                            rules={[requiredRule]}
                                        >
                                            <Select placeholder="Sélectionner un type">
                                                {Object.values(ValueListFieldValueType).map((valueType) => (
                                                    <Select.Option key={valueType} value={valueType}>
                                                        {valueType}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item shouldUpdate noStyle>
                                            {({ getFieldValue }) =>
                                                getFieldValue(["fields", field.name, "valueType"]) ===
                                                    ValueListFieldValueType.number && (
                                                    <div className="flex gap-4 w-full">
                                                        <Form.Item
                                                            label="Valeur min"
                                                            name={[field.name, "minValue"]}
                                                            className="flex-1"
                                                        >
                                                            <InputNumber
                                                                placeholder="Saisir une valeur"
                                                                className="w-full"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Valeur max"
                                                            name={[field.name, "maxValue"]}
                                                            className="flex-1"
                                                        >
                                                            <InputNumber
                                                                placeholder="Saisir une valeur"
                                                                className="w-full"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                )
                                            }
                                        </Form.Item>
                                        <Form.Item label="Unité de valeur" name={[field.name, "unit"]} className="mb-0">
                                            <Select placeholder="Sélectionner une unité">
                                                {Object.values(ValueListFieldUnit).map((unit) => (
                                                    <Select.Option key={unit} value={unit}>
                                                        {unit}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Card>
                                ))}

                                <Button size="large" onClick={() => add()} icon={<PlusOutlined />} block>
                                    Ajouter un champ
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Spin>
        </Drawer>
    );
});

export default ValueListFormDrawer;
