import api from "./apiClient";
import { SearchPaginationQuery } from ".";
import { Backup, Application, ListResponse } from "./types";

export type BackupListPayload = SearchPaginationQuery & {
    applicationId?: string;
};
export type BackupListResponse = ListResponse<Backup>;

// Routes
export const list = async (payload: BackupListPayload = {}) => {
    const applicationId = payload.applicationId || "all";
    return await api
        .get<BackupListResponse>(`/applications/${applicationId}/backups`, { params: payload })
        .then((response) => response?.data);
};

export interface BackupIdPayload {
    id: Backup["id"];
}
export const details = async ({ id }: BackupIdPayload) => {
    return await api.get<Backup>(`/backups/${id}`).then((response) => response?.data);
};

export interface BackupCreatePayload {
    application: Application["id"];
    source: Backup["source"];
}
export const create = async ({ application, ...payload }: BackupCreatePayload) => {
    return await api.post<Backup>(`/applications/${application}/backups`, payload).then((response) => response?.data);
};

export const remove = async ({ id }: BackupIdPayload) => {
    return await api.delete<undefined>(`/backups/${id}`).then((response) => response?.data);
};
