import { Button, Drawer, DrawerProps, Form, FormProps, Input, Typography } from "antd";
import { antdDrawer, useModal, create } from "@ebay/nice-modal-react";

import { useAuth } from "../../context/AuthContext";
import { defaultErrorMessage, requiredFieldsText } from "../../i18n";
import { errorMessage, successMessage } from "../../helpers/message";
import validatePasswordRules from "../../helpers/passwords";
import PasswordInput from "../PasswordInput";
import { requiredRule } from "../../helpers";

type Props = DrawerProps & Record<string, any>;

const PasswordFormDrawer = create<Props>((props) => {
    const modal = useModal();
    const [form] = Form.useForm();
    const { updateUser } = useAuth();
    const { mutate: update, isLoading } = updateUser;
    const onFormValidSubmit: FormProps["onFinish"] = (values) => {
        update(values, {
            onSuccess: () => {
                modal.hide();
                successMessage({
                    content: "Votre mot de passe a été modifié avec succès",
                });
            },
            onError: (error) => {
                errorMessage({
                    content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
                });
            },
        });
    };

    return (
        <Drawer width={500} title="Modifier le mot de passe" {...props} {...antdDrawer(modal)}>
            <Form form={form} onFinish={onFormValidSubmit} layout="vertical" scrollToFirstError>
                <Form.Item label="Mot de passe actuel" name="oldPassword" rules={[requiredRule]}>
                    <Input.Password placeholder="Saisir votre mot de passe" />
                </Form.Item>
                <Form.Item
                    label="Nouveau mot de passe"
                    rules={[
                        requiredRule,
                        {
                            validator: async (_, value) => {
                                if (!validatePasswordRules(value)) {
                                    return await Promise.resolve();
                                }
                                return await Promise.reject(new Error("Veuillez entrer un mot de passe valide"));
                            },
                        },
                    ]}
                    name="password"
                >
                    <PasswordInput placeholder="Saisir votre nouveau mot de passe" />
                </Form.Item>
                <Form.Item className="mb-32 mt-32" shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={isLoading}
                            disabled={
                                !form.isFieldsTouched(true) ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                            }
                            block
                        >
                            Modifier le mot de passe
                        </Button>
                    )}
                </Form.Item>
                <Typography.Paragraph className="text-center mb-0">
                    <Typography.Text className="font-12">{requiredFieldsText}</Typography.Text>
                </Typography.Paragraph>
            </Form>
        </Drawer>
    );
});

export default PasswordFormDrawer;
