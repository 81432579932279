import { FC, useCallback } from "react";
import Table, { ColumnProps, TableProps } from "antd/lib/table";
import { Button, Input, InputProps, message, Popconfirm, Result, Space } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { show } from "@ebay/nice-modal-react";

import { Organization } from "../../../queries/api/types";
import { useOrganizationRemove, useOrganizationsList } from "../../../queries/organizations";
import useQueryParams from "../../../hooks/queryParams";
import Seo from "../../../components/Seo";
import ListTitle from "../../../components/ListTitle";
import LayoutHeader from "../../../components/LayoutHeader";
import OrganizationFormDrawer from "./OrganizationFormDrawer";

let searchTimeout: number;

const OrganizationsList: FC = () => {
    const [queryParams, setQueryParams] = useQueryParams("organizations-list");
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const search = queryParams.get("search") ?? undefined;
    const { data: organizationsData, isLoading, isFetching, isError, error } = useOrganizationsList({ page, search });
    const organizationRemove = useOrganizationRemove({
        onError: () => {
            message.error("Une erreur est survenue pendant la suppression l'organisation");
        },
        onSuccess: () => {
            message.success("Organisation supprimée avec succès");
        },
    });
    const onTableChange: TableProps<Organization>["onChange"] = (pagination) => {
        setQueryParams({
            page: (pagination.current ?? 1) - 1,
        });
    };
    const onSearch: InputProps["onChange"] = useCallback(
        (e) => {
            const value = e.target.value;
            if (searchTimeout) {
                window.clearTimeout(searchTimeout);
            }
            searchTimeout = window.setTimeout(() => {
                setQueryParams({
                    search: value?.length ? value : undefined,
                    page: undefined,
                });
            }, 300);
        },
        [setQueryParams]
    );
    const createOrganizationModalVisible = () => {
        show(OrganizationFormDrawer);
    };
    const onClickRemoveOrganizationButton = (id: Organization["id"]) => {
        organizationRemove.mutate(id);
    };
    const onClickUpdateOrganizationButton = (id: Organization["id"]) => {
        show(OrganizationFormDrawer, { organizationId: id });
    };
    const columns: Array<ColumnProps<Organization>> = [
        {
            title: "ID",
            dataIndex: "id",
            width: "18%",
        },
        {
            title: "Nom de l'organisation",
            dataIndex: "name",
        },
        {
            title: "Actions",
            dataIndex: "id",
            width: 128,
            render: (id: string) => {
                return (
                    <div className="space-x-3">
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onClickUpdateOrganizationButton.bind(null, id)}
                        />

                        <Popconfirm
                            placement="topLeft"
                            title={"Êtes-vous sûr de supprimer cette organisation ?"}
                            onConfirm={onClickRemoveOrganizationButton.bind(null, id)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button
                                className="actions-buttons"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                loading={organizationRemove.isLoading}
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Seo title="Organizations" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={organizationsData?.totalCount} className="mb-0">
                        Organisations
                    </ListTitle>
                    <Button
                        className="header-button"
                        size="large"
                        type="primary"
                        onClick={createOrganizationModalVisible}
                    >
                        Ajouter une organisation
                    </Button>
                </div>
            </LayoutHeader>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Input
                    placeholder="Rechercher un nom..."
                    onChange={onSearch}
                    defaultValue={search}
                    prefix={<SearchOutlined className="text-primary text-base leading-4" />}
                    size="large"
                    allowClear
                />
                {isError ? (
                    <Result status={error?.request?.status} />
                ) : (
                    <Table<Organization>
                        className="organizations-list"
                        columns={columns}
                        rowKey="id"
                        loading={isLoading || isFetching}
                        dataSource={organizationsData?.items}
                        onChange={onTableChange}
                        expandable={{
                            childrenColumnName: "notChildren", // prevents antd from adding an expandable row when the data contains a "children" prop
                        }}
                        pagination={{
                            total: organizationsData?.totalCount,
                            current: page + 1,
                            pageSize: organizationsData?.pageSize,
                            hideOnSinglePage: true,
                        }}
                    />
                )}
            </Space>
        </>
    );
};

export default OrganizationsList;
