import React, { useCallback, useState, VFC } from "react";
import { Button, message, Popconfirm, Result, Space, Table, Tag } from "antd";
import { TableProps } from "antd/lib/table";
import { DeleteOutlined, RedoOutlined, EyeOutlined } from "@ant-design/icons";
import { show } from "@ebay/nice-modal-react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import { AgendaJob, AgendaJobStatus } from "../../../queries/api/types";
import { useJobList, useJobRemove, useJobUpdate } from "../../../queries/jobs";
import useQueryParams from "../../../hooks/queryParams";
import LayoutHeader from "../../../components/LayoutHeader";
import ListTitle from "../../../components/ListTitle";
import JobFormDrawer, { JobFormDrawerProps } from "./JobFormDrawer";
import Seo from "../../../components/Seo";
import { sortOrderConverter, addDefaultColumnSorting } from "../../../helpers";
import JobNameSelect from "./JobNameSelect";

const JobList: VFC = () => {
    const [updatingJob, setUpdatingJob] = useState<AgendaJob["name"]>("");
    const [queryParams, setQueryParams] = useQueryParams("jobs-list");
    const [refetchInterval, setRefetchInterval] = useState(5000);
    const page = queryParams.get("page") !== null ? parseInt(queryParams.get("page")!, 10) || 0 : 0;
    const status = (queryParams.getAll("status") as AgendaJobStatus[]) ?? undefined;
    const sort = queryParams.get("sort") ?? undefined;
    const sortOrder = queryParams.get("sortOrder") ?? undefined;
    const name = (queryParams.getAll("name") as AgendaJob["name"][]) ?? undefined;

    const {
        data: jobsData,
        isLoading,
        isFetching,
        isError,
        error,
    } = useJobList({ page, status, sort, sortOrder, name }, { refetchInterval, onError: () => setRefetchInterval(0) });

    const onTableChange: TableProps<AgendaJob>["onChange"] = useCallback(
        (pagination, filters, sorter) => {
            const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;
            setQueryParams({
                page: (pagination.current ?? 1) - 1,
                status: filters.status ? filters.status : undefined,
                sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
                sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
                name: filters.name ? filters.name : undefined,
            });
        },
        [setQueryParams]
    );

    const jobRemove = useJobRemove({
        onError: () => {
            message.error("Une erreur est survenue pendant la suppression du job");
        },
        onSuccess: () => {
            message.success("Rôle supprimé avec succès");
        },
    });

    const jobUpdate = useJobUpdate({
        onError: () => {
            message.error("Une erreur est survenue pendant la relance du job");
        },
        onSuccess: () => {
            message.success("Rôle relancé avec succès");
        },
    });

    const onClickRemoveJobButton = (id: AgendaJob["id"]) => {
        jobRemove.mutate(id);
    };

    const onClickRunJobButton = (id: AgendaJob["id"], job: AgendaJob) => {
        setUpdatingJob(job.name);
        const now = new Date();
        jobUpdate.mutate(
            { id: id, nextRunAt: now.toString(), lockedAt: null },
            {
                onSuccess: () => {
                    setUpdatingJob("");
                },
            }
        );
    };

    const onClickShowJobButton = (id: AgendaJob["id"]) => {
        show<JobFormDrawerProps>(JobFormDrawer, { jobId: id });
    };

    const formatedDateDiff = (dateString: string) => {
        const date = dayjs(dateString);
        const formatedDateDiff = dayjs().to(date);
        return dateString ? formatedDateDiff.charAt(0).toUpperCase() + formatedDateDiff.slice(1) : "-";
    };

    const getJobStatusLabel = (status: AgendaJobStatus) => {
        switch (status) {
            case "running":
                return <Tag color={"orange"}>{status}</Tag>;
            case "finished":
                return <Tag color={"green"}>{status}</Tag>;
            case "scheduled":
                return <Tag color={"blue"}>{status}</Tag>;
            case "failed":
                return <Tag color={"red"}>{status}</Tag>;
            default:
                return <Tag color={"default"}>Unknown status : {status}</Tag>;
        }
    };

    const getJobStatus = (statusList: AgendaJobStatus[]) => {
        return statusList.map((_status, i) => {
            return <span key={i}>{getJobStatusLabel(_status)}</span>;
        });
    };

    const columns = addDefaultColumnSorting<AgendaJob>(sort, sortOrder, [
        {
            title: "Status",
            width: "20%",
            key: "status",
            dataIndex: "status",
            filters: [
                {
                    text: "running",
                    value: "running",
                },
                {
                    text: "finished",
                    value: "finished",
                },
                {
                    text: "scheduled",
                    value: "scheduled",
                },
                {
                    text: "failed",
                    value: "failed",
                },
            ],
            render: (status: AgendaJobStatus[]) => getJobStatus(status),
            filteredValue: status,
        },
        {
            title: "Nom du job",
            key: "name",
            dataIndex: "name",
            sorter: true,
            filteredValue: name,
            filterDropdown: JobNameSelect,
        },
        {
            title: "Dernière activation ",
            key: "lastRunAt",
            dataIndex: "lastRunAt",
            render: (lastRunAt: string) => formatedDateDiff(lastRunAt),
            sorter: true,
        },
        {
            title: "Prochaine activation",
            key: "nextRunAt",
            dataIndex: "nextRunAt",
            render: (nextRunAt: string) => formatedDateDiff(nextRunAt),
            sorter: true,
        },
        {
            title: "Dernière exécution",
            key: "lastFinishedAt",
            dataIndex: "lastFinishedAt",
            render: (lastFinishedAt: string) => formatedDateDiff(lastFinishedAt),
            sorter: true,
        },
        {
            title: "Actions",
            key: "id",
            dataIndex: "id",
            width: 150,
            render: (id: string, obj: AgendaJob) => {
                return (
                    <div className="space-x-2">
                        {obj && (
                            <>
                                <Popconfirm
                                    placement="topLeft"
                                    title={"Relancer le job ?"}
                                    onConfirm={onClickRunJobButton.bind(null, id, obj)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        loading={updatingJob === obj.name}
                                        disabled={updatingJob ? updatingJob !== obj.name : false}
                                        className="actions-buttons"
                                        shape="circle"
                                        icon={<RedoOutlined />}
                                    />
                                </Popconfirm>
                                <Popconfirm
                                    placement="topLeft"
                                    title={"Êtes-vous sûr de supprimer ce job ?"}
                                    onConfirm={onClickRemoveJobButton.bind(null, id)}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button
                                        className="actions-buttons"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        loading={false}
                                    />
                                </Popconfirm>
                                <Button
                                    className="actions-buttons"
                                    shape="circle"
                                    icon={<EyeOutlined />}
                                    onClick={onClickShowJobButton.bind(null, id)}
                                />
                            </>
                        )}
                    </div>
                );
            },
        },
    ]);

    return (
        <>
            <Seo title="Jobs" />
            <LayoutHeader>
                <div className="flex justify-between items-center">
                    <ListTitle count={jobsData?.totalCount} className="mb-0">
                        Jobs
                    </ListTitle>
                </div>
            </LayoutHeader>

            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                {isError ? (
                    <Result status={error?.request?.status} />
                ) : (
                    <Table<AgendaJob>
                        columns={columns}
                        rowKey="id"
                        loading={isLoading || isFetching}
                        dataSource={jobsData?.items}
                        onChange={onTableChange}
                        pagination={{
                            total: jobsData?.totalCount,
                            current: page + 1,
                            pageSize: jobsData?.pageSize,
                            hideOnSinglePage: true,
                        }}
                    />
                )}
            </Space>
        </>
    );
};

export default JobList;
