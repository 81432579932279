import { Badge, Typography } from "antd";
import { FC } from "react";
import { TitleProps } from "antd/lib/typography/Title";

import "../assets/styles/ListTitle.less";
import { classNames } from "../helpers";

interface ListTitleProps extends TitleProps {
    count?: number;
}

const ListTitle: FC<ListTitleProps> = ({ children, count, level = 1, className }) => (
    <Typography.Title level={level} className={classNames("list-title", className)}>
        {children}
        <Badge count={count ?? 0} />
    </Typography.Title>
);

export default ListTitle;
