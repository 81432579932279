import { compile } from "path-to-regexp";

import { debug } from "./helpers";

export enum RoutePathName {
    usersList = "usersList",
    usersDetails = "usersDetails",
    home = "home",
    login = "login",
    notFound = "notFound",
    privacy = "privacy",
    forgottenPassword = "forgottenPassword",
    resetPassword = "resetPassword",
    customerList = "customerList",
    test = "test",

    // Super Admin
    superAdmin_applicationList = "superAdmin_applicationList",
    superAdmin_organizationList = "superAdmin_organizationList",
    superAdmin_valueListList = "superAdmin_valueListList",
    superAdmin_roleList = "superAdmin_roleList",
    superAdmin_jobList = "superAdmin_jobList",

    // Backups
    applicationsList = "applicationsList",
    backupsList = "backupsList",
    restoresList = "restoresList",
    agentsList = "agentsList",
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.usersList]: "/users",
    [RoutePathName.usersDetails]: "/users/:userId",
    [RoutePathName.home]: "/",
    [RoutePathName.login]: "/login",
    [RoutePathName.notFound]: "/404",
    [RoutePathName.privacy]: "/confidentialite",
    [RoutePathName.forgottenPassword]: "/mot-de-passe-oublie",
    [RoutePathName.resetPassword]: "/reset-password",
    [RoutePathName.customerList]: "/clients",
    // enum based param example, any param that does not match the enum will result in a 404
    [RoutePathName.test]: `/test/:param(${Object.values(RoutePathName).join("|")})`,

    // Super Admin
    [RoutePathName.superAdmin_organizationList]: "/super-admin/organizations",
    [RoutePathName.superAdmin_applicationList]: "/super-admin/applications",
    [RoutePathName.superAdmin_valueListList]: "/super-admin/values-lists",
    [RoutePathName.superAdmin_roleList]: "/super-admin/roles",
    [RoutePathName.superAdmin_jobList]: "/super-admin/jobs",

    // Backups
    [RoutePathName.applicationsList]: "/applications",
    [RoutePathName.backupsList]: "/backups",
    [RoutePathName.restoresList]: "/restores",
    [RoutePathName.agentsList]: "/agents",
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return "/404";
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string
) => {
    let route = getRawRoute(path);
    const compiler = compile(route, { encode: encodeURIComponent });

    try {
        route = compiler(params);
    } catch (error) {
        debug.error("[getRoute] route compile error :", error);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
